'use strict';

import GC from "./const";

var ScenePreface = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreface ()
  {
    Phaser.Scene.call(this, { key: 'scenePreface', active: false });

    this.currentLan = 'cn';
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    // hack for only Chinese gallery
    if (this.params.galleryId === 1) {
      if(this.currentLan === 'en') {
        this.currentLan = 'cn';
      }
    }    

    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'gallery_preface_bg' + '_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    if (this.params.galleryId === 1) {
      designX = -651;
      designY = 110;  
    } else if(this.params.galleryId === 2) {
      designX = -709;
      designY = 121;
    } else {
      designX = -600;
      designY = 213;
    }
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'gallery_preface_' + this.currentLan + '_' + this.params.galleryId
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
    };
    this.prefaceSprite = sprite;

    if(utils.hasLanguageButton(this.params.galleryId)) {
      designX = 168;
      designY = 762.5;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX),
        this.scaler.scaleY(designY),
        'gallery_btn_lan_preface_' + this.currentLan + "_" + this.params.galleryId
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY,
        scaleBgX: true,
        enableClick: true,
      };
      this.btnLanSprite = sprite;
      if (this.params.galleryId === 1) {
        sprite.setVisible(false);
      }
      
      sprite.setInteractive();
      
      sprite.on("pointerdown", pointer => {
        this.btnLanSprite.isPressed = true;
        this.btnLanSprite.alpha = 0.5;
      });
  
      sprite.on("pointerup", pointer => {
        if(this.btnLanSprite.isPressed) {
          this.changeLanguage()
        }
        this.btnLanSprite.isPressed = false;
        this.btnLanSprite.alpha = 1;
      });
    }

    designX = 168;
    designY = 942;
    if (this.params.galleryId === 2) {
      designX = 76;
      designY = 934;
    }
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'gallery_btn_back_preface_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    if (this.params.galleryId === 1) {
      designX = -243;
      designY = 891;  
    } else if(this.params.galleryId === 2) {
      designX = -130;
      designY = 491;  
    } else {
      designX = -147;
      designY = 574;  
    }
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'gallery_btn_next_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
    };

    const btnNextSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnNextSprite.isPressed = true;
      btnNextSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnNextSprite.isPressed) {
        this.next();
      }
      btnNextSprite.isPressed = false;
      btnNextSprite.alpha = 1;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
      });
    }

  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  changeLanguage: function() {

    if(this.currentLan === 'cn') {
      this.currentLan = 'en';
    } else {
      this.currentLan = 'cn';
    }

    this.prefaceSprite.setTexture('gallery_preface_' + this.currentLan + '_' + this.params.galleryId);
    this.btnLanSprite.setTexture('gallery_btn_lan_preface_' + this.currentLan + '_' + this.params.galleryId);
  },

  back: function() {
    this.scene.start('sceneGallery', {
      animation: false,
    });
  },

  next: function() {
    const self = this;
    utils.disableAllButtons(this);
      
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.animationing = false;
      self.scene.stop();
    });
    
    this.scene.launch('sceneGalleryInfo', {
      galleryId: this.params.galleryId,
      animation: true,
    });
  },
});
export default ScenePreface;