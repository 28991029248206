'use strict';

import Scroller from "./scroller";

var SceneGallery = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneGallery ()
  {
    Phaser.Scene.call(this, { key: 'sceneGallery', active: false });
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'bg_menu'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    designX = 255;
    designY = 206;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'main_exhibition'
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
    };

    this.scroller = new Scroller(this, this.scaler, 'Horizontal');

    designX = 0;
    designY = 603.5;
    sprite = this.add.rectangle(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      this.scaler.designRefWidth(),
      540
      , 0xffffff, 0);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    const scrollerBgSprite = sprite;

    const gallerySprites = []
    for(let i = 0; i < 3; i ++) {
      (function(i) {
        designX = 539 + 660 * i;
        designY = 360;
        sprite = self.add.image(
          self.scaler.scaleBgX(designX),
          self.scaler.scaleY(designY),
          'gallery_' + i
        );
        sprite.setOrigin(0.5, 0);
        self.scaler.scaleSprite(sprite);
        sprite.keepData = {
          resized: true,
          // xlocation: 'center',
          designX: designX,
          designY: designY,
        };
        gallerySprites.push(sprite);
  
        // if(i === 0 || i === 1) {
          sprite.setInteractive({ draggable: true });
          sprite.keepData.enableClick = true;
        // }
      
        sprite.on("pointerdown", pointer => {
          if(self.scroller.dragMaxOffset > 5) {
            return
          }
          
          gallerySprites[i].isPressed = true;
          gallerySprites[i].alpha = 0.5;
        });
  
        sprite.on("pointerup", pointer => {
          if(self.scroller.dragMaxOffset > 5) {
            return
          }

          if(gallerySprites[i].isPressed) {
            self.toGallery(i);
          }
          gallerySprites[i].isPressed = false;
          gallerySprites[i].alpha = 1;
        });
      })(i);
    }

    this.scroller.setDragBg(scrollerBgSprite);

    if(gallerySprites.length > 0) {
      this.scroller.setDragSprites(gallerySprites);
      this.scroller.setScrollerBounds({
        left: 256,
        right: 0,
        rightEnd: -256,
      });
    }

    designX = 168;
    designY = 942;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_back_white'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
      });
    }
  },

  update: function(){
    this.scroller.checkEnding();
    this.scroller.checkCrop();
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  toAuthorList: function() {
    this.outAnimation();
    this.scene.launch('sceneAuthorList', {
      animation: true,
    });
  },

  toGallery: function(i) {
    // this.outAnimation();
    // utils.toGallery(this.scene, i);
    this.scene.start('scenePreface', {
      galleryId: i,
    });
  },

  back: function() {
    this.scene.start('sceneEnter', {
      animation: false,
    });
  },

  outAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.animationing = false;
      self.scene.stop();
    });
  },
});
export default SceneGallery;