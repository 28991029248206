import GC from "./const";

'use strict';

var SceneSideBar = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneSideBar ()
  {
    Phaser.Scene.call(this, { key: 'sceneSideBar', active: false });
  },

  init: function(params){
    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'height';
    this.scaler.scaleMode = 'height';

    this.sceneBoot.windowResized = true;

    this.params = params;

    this.scene.bringToTop();

    this.galleryId = 0;

    if(params.galleryId != undefined) {
      this.galleryId = params.galleryId;
    }
  },

  preload: function ()
  {
  },

  create: function ()
  {
    console.log('sidebar ..');
    // this.cameras.main.alpha = 0;

    var self = this;
    var designX, designY, sprite;

    // designX = 0;
    // designY = 0;
    // sprite = this.add.rectangle(
    //   this.scaler.scaleX(designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   GC.sidebarWidth + window.sidebarOffset,
    //   this.scaler.designRefHeight()
    //   , 0x000000, 1);
    // sprite.setOrigin(0, 0.5);
    // this.scaler.scaleSpriteByHeight(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   ylocation: 'center',
    //   designX: designX,
    //   designY: designY,
    //   offset: 'width',
    //   initialWidth: GC.sidebarWidth,
    // };
    // sprite.setInteractive();
    // this.sidebarBgSprite = sprite;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'gallery_sidebar_bg_' + this.params.galleryId
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      ylocation: 'center',
      designX: designX,
      designY: designY,
      offset: 'width',
      initialWidth: GC.sidebarWidth,
    };
    sprite.setInteractive();

    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 30;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'gallery_sidebar_text_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    sprite.setInteractive();

    // var galleryIndex = 0;
    // if(this.params.galleryIndex != undefined) {
    //   galleryIndex = this.params.galleryIndex;
    // }
    // designX = window.sidebarOffset + GC.sidebarWidth/2;
    // designY = 0;
    // sprite = this.add.image(
    //   this.scaler.scaleX(designX),
    //   this.scaler.scaleY(designY),
    //   'sidebar' + galleryIndex
    // );
    // sprite.setOrigin(0.5, 0);
    // this.scaler.scaleSpriteByHeight(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   designX: designX,
    //   designY: designY,
    //   y: 180,
    //   asisY: 138,
    //   offset: 'x',
    //   initialX: GC.sidebarWidth/2,
    // };
    // this.sidebarTextSprite = sprite;

    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 742;
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'gallery_btn_contents_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x,
      enableClick: true,
      alpha: 1,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    this.btnContentsSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnContentsSprite.alpha = 0.6;

      this.btnContentsSprite.isPressed = true;
    });
    sprite.on("pointerup", pointer => {
      this.btnContentsSprite.alpha = 1.0;

      if(this.btnContentsSprite.isPressed) {
        this.toContents();
      }

      this.btnContentsSprite.isPressed = false;
    });

    if (this.params.galleryId === 0) {
      designX = window.sidebarOffset + GC.sidebarWidth/2;
      designY = 871.5;
      sprite = this.add.image(
        this.scaler.scaleX(designX),
        this.scaler.scaleY(designY),
        'gallery_btn_lan_' + this.params.lan + "_" + this.params.galleryId
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSpriteByHeight(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY,
        x: sprite.x,
        enableClick: true,
        alpha: 1,
        offset: 'x',
        initialX: GC.sidebarWidth/2,
      };
      this.btnLanSprite = sprite;
      if (this.params.galleryId === 1) {
        sprite.setVisible(false)
      }

      sprite.setInteractive();
      sprite.on("pointerdown", pointer => {
        this.btnLanSprite.alpha = 0.6;

        this.btnLanSprite.isPressed = true;
      });
      sprite.on("pointerup", pointer => {
        this.btnLanSprite.alpha = 1.0;

        if(this.btnLanSprite.isPressed) {
          this.toSwitch();
        }

        this.btnLanSprite.isPressed = false;
      });
    }

    designX = window.sidebarOffset + GC.sidebarWidth/2;
    designY = 1000.5;
    if (this.params.galleryId === 2) {
      designY = 934;
    }
    sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'gallery_btn_back_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      x: sprite.x,
      enableClick: true,
      alpha: 1,
      offset: 'x',
      initialX: GC.sidebarWidth/2,
    };
    this.btnBackSprite = sprite;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnBackSprite.alpha = 0.6;

      this.btnBackSprite.isPressed = true;
    });
    sprite.on("pointerup", pointer => {
      this.btnBackSprite.alpha = 1.0;

      if(this.btnBackSprite.isPressed) {
        this.toBack();
      }

      this.btnBackSprite.isPressed = false;
    });

    // designX = window.sidebarOffset + GC.sidebarWidth/2;
    // designY = 1018.5;
    // sprite = this.add.bitmapText(
    //   this.scaler.scaleX(designX),
    //   this.scaler.scaleY(designY),
    //   'numberFont',
    //   '', 18).setOrigin(0.5, 0);//.setCenterAlign();
    // this.scaler.scaleSpriteByHeight(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'right',
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.setText('');
    // sprite.alpha = 0.7;
    // this.regionNumberText = sprite;

    // this.hideRegionLabel();    

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(!this.params.noAnimation) {
      utils.disableAllButtons(this);

      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);  
      });
    }
  },

  update: function() {
  },

  resized: function() {
    if(this.animationing) {
      return;
    }
    var phaserElements = this.children.list;
    for(var i = 0; i < phaserElements.length; i ++){
      var s = phaserElements[i];
      if(s.keepData && s.keepData.resized == true){
        if(s.keepData.offset == 'width') {
          s.keepData.width = s.keepData.initialWidth + window.sidebarOffset;
        }
        if(s.keepData.offset == 'x') {
          s.keepData.designX = s.keepData.initialX + window.sidebarOffset;
        }

        if(s.keepData.xlocation && s.keepData.xlocation == 'center'){
          s.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + s.keepData.designX);
        } else{
          s.x = this.scaler.scaleX(s.keepData.designX);
        }

        if(s.keepData.ylocation && s.keepData.ylocation == 'bottom'){
          s.y = this.scaler.scaleY(this.scaler.designRefHeight() + s.keepData.designY);
        } else if(s.keepData.ylocation && s.keepData.ylocation == 'center'){
          s.y = this.scaler.scaleY(this.scaler.designRefHeight()/2 + s.keepData.designY);
        } else{
          s.y = this.scaler.scaleY(s.keepData.designY);
        }
        
        if(s.keepData.fill){
          this.scaler.scaleSpriteFill(s);
        } else{
          this.scaler.scaleSpriteByHeight(s);
        }
        if(s.keepData.scale){
          s.scale *= s.keepData.scale;
        }
      
        if(s.keepData.width) {
          s.displayWidth = s.keepData.width * this.scaler.vScale();
        }
      }
    }


  },

  toContents: function() {
    if(this.scene.get('sceneMain').viewer.current && this.scene.get('sceneMain').viewer.current.portfolio) {
      this.scene.get('sceneMain').viewer.current.portfolio.hide();
    }
    
    this.scene.get('sceneMain').viewer.students.show();
  },

  toSwitch: function() {
    var self = this;
    this.delCookies();

    if(self.scene.get('sceneMain').viewer) {
      self.scene.get('sceneMain').viewer.jumpToFirstRegion();  
    }
    if(self.scene.get('sceneMain').viewer) {
      clearTimeout(self.scene.get('sceneMain').viewer.jumpTimer);
    }
    self.scene.get('sceneMain').scene.stop();

    self.scene.get('sceneLoading').closeLoadingMask();

    let lan = 'en'
    if(this.params.lan === 'en') {
      lan = 'cn'
    }
    this.scene.get('sceneGalleryInfo').toGallery(lan, this.params.prefaceLan);
  },

  toBack: function() {
    this.delCookies();

    if(this.scene.get('sceneMain').viewer) {
      this.scene.get('sceneMain').viewer.jumpToFirstRegion();  
    }
    if(this.scene.get('sceneMain').viewer) {
      clearTimeout(this.scene.get('sceneMain').viewer.jumpTimer);
    }
    this.scene.get('sceneMain').scene.stop();

    this.scene.get('sceneLoading').closeLoadingMask();

    this.scene.stop();

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';
    this.sceneBoot.windowResized = true;
    
    // this.scene.get('sceneGalleryInfo').show();

    this.scene.start('sceneGalleryInfo',  {
      galleryId: this.params.galleryId,
      animation: false,
      prefaceLan: this.params.prefaceLan
    });
  },

  delCookies: function(){
    utils.delCookie('selected');
    utils.delCookie('locator');
  },

  enterAnimation: function(func_callback){
    utils.slideAimation(this, 'in', function() {
      if(func_callback) {
        func_callback();
      }
    });
  },

  outAnimation: function(func) {
    var self = this;
    this.animationing = true;

    utils.slideAimation(this, 'back', function() {
      if(func) {
        func();
      }
      self.animationing = false;
    });
  },

  dialogIsOpend: function() {
    let result = false;
    if(this.galleryDialog.getChildren()[0].visible) {
      result = true;
      this.galleryDialog.setVisible(false);
    }
    
    return result;
  },
});
export default SceneSideBar;