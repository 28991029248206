import { relativeTimeRounding } from "moment";

var EventEmitter = require('events').EventEmitter;

function Students(scene, scaler, names, viewer) {
  this.scene = scene;
  this.scaler = scaler;
  this.viewer = viewer;
  this.depth = 60000;
  this.depthNames = 70000;
  this.group = this.scene.add.group();
  this.btnPortfolio;
  this.btnShare;
  this.nameSprites = [];

  this.dragSprites = [];
  this.names = names;
  this.allowDown = true;
  this.allowUp = true;

  this.cellWidth = 420;
  this.cellHeight = 330;
  this.spacingW = 100;
  this.spacingH = 40;

  this.lan = 'cn';
  if(window.custom.selected.lan) {
    this.lan = window.custom.selected.lan;
  }

  if(this.lan === 'cn') {
    this.cellHeight = 268;
  }

  this.scrollerBarBounds = {
    top: 326,
    bottom: 1080 - 60,
  };

  this.on('dragstart-students', function () {
    this.dragSprites.map(sprite => {
      sprite.keepData.x = sprite.x;
      sprite.keepData.y = sprite.y;
    });
  });

  this.on('drag-students', function(evt) {
    if(evt.offset < 0 && this.allowDown == false)
      return;
    if(evt.offset > 0 && this.allowUp == false)
      return;
    let v = 2;
    this.dragSprites.map(sprite => {
      sprite.y = sprite.keepData.y + evt.offset * v;
    });
  });

  this.on('dragend-students', function(evt) {
    this.dragSprites.map(sprite => {
      sprite.alpha = sprite.keepData.alpha;
    });
  }, this);

  this.on('wheel-students', function(evt) {
    if(evt.offset < 0 && this.allowDown == false)
      return;
    if(evt.offset > 0 && this.allowUp == false)
      return;
    let v = 2;
    this.dragSprites.map(sprite => {
      sprite.keepData.y = sprite.y;
      sprite.y = sprite.keepData.y - evt.offset * v;
    });
  });
}

Students.prototype.__proto__ = EventEmitter.prototype;

Students.prototype.preload = function() {
  // this.scene.textures.removeKey('btn_close_student');
};

Students.prototype.create = function() {
  var self = this;
  let sprite, designX, designY;
  
  this.displayBounds = {
    left: this.scaler.scaleX(GC.sidebarWidth + window.sidebarOffset + 111),
    top: this.scaler.scaleY(60),
    bottom: this.scaler.scaleY(this.scaler.designRefHeight() - 60),
    width: this.scaler.scaleX(this.scaler.designRefWidth() - (GC.sidebarWidth + 111 + 354)),
    height: this.scaler.scaleY(this.scaler.designRefHeight() - 60*2)
  };

  designX = 0;
  designY = 0;
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'gallery_shadow_bg_' + this.scene.params.galleryId
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSprite(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'center',
    ylocation: 'center',
    locationByBg: true,
    designX: designX,
    designY: designY,
    fill: true,
  };
  sprite.depth = this.depthNames;
  this.group.add(sprite);
  this.bgSprite = sprite;

  designX = 224;
  designY = 170;
  if (this.scene.params.galleryId === 2) {
    designX = 247;
    designY = 151;
  }
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'gallery_menu_' + this.lan + "_" + this.scene.params.galleryId
  );
  sprite.setOrigin(0, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depthNames;
  this.scaler.scaleSpriteByHeight(sprite);
  this.group.add(sprite);

  // designX = 0;
  // designY = 0;
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
  //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
  //   'bg_loading_' + window.custom.selected.galleryType
  // );
  // sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteFill(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   xlocation: 'center',
  //   ylocation: 'center',
  //   fill: true,
  //   designX: designX,
  //   designY: designY
  // };
  // sprite.depth = this.depthNames;
  // this.group.add(sprite);
  
  // close button
  if (this.scene.params.galleryId === 0) {
    designX = -120;
    designY = 0;
  } else if (this.scene.params.galleryId === 1) {
    designX = -132;
    designY = -354;  
  } else if (this.scene.params.galleryId === 2) {
    designX = -112;
    designY = -428;  
  }
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    'gallery_btn_close_' + this.scene.params.galleryId
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    ylocation: 'center',
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depthNames;
  this.scaler.scaleSpriteByHeight(sprite);

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.closeSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.closeSprite.alpha = 1.0;
    this.hide();
  });
  this.group.add(sprite);
  this.closeSprite = sprite;

  // designX = -90;
  // designY = 130;
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(this.scaler.designRefWidth() + designX),
  //   this.scaler.scaleY(designY),
  //   'scrollerbar'
  // );
  // sprite.setOrigin(0.5, 0);
  // sprite.keepData = {
  //   resized: true,
  //   xlocation: 'right',
  //   designX: designX,
  //   designY: designY
  // };
  // sprite.depth = this.depthNames;
  // this.scaler.scaleSpriteByHeight(sprite);
  // this.group.add(sprite);
  // this.scrollerbarSprite = sprite;

  // sprite.setInteractive({ draggable: true });

  // sprite.on('drag', function (pointer, dragX, dragY) {
  //   if(self.scaler.getDirection() == 'Horizontal'){
  //     self.scrollerbarSprite.y = dragY;
  //   } else{
  //   }
  //   this.dragScrollerbar();
  // }, this);

  // sprite.on('dragstart', function (pointer, dragX, dragY) {
  // }, this);

  // sprite.on('dragend', function (pointer, dragX, dragY) {
  // }, this);

  // sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
  //   self.emit('wheel-students', {offset: deltaY * 0.4});
  // }, this);

  this.nameList();

  this.createDragSprite();

  // this.hide();
  this.group.setVisible(false);
};

Students.prototype.update = function() {
  if(this.closeSprite && this.closeSprite.visible == true){
    this.checkEnding();
    this.checkCrop();
    // this.updateScrollerbar();
  }
};

Students.prototype.resize = function() {
  let screenWidth = this.scaler.width;
  let screenHeight = this.scaler.height;

  this.displayBounds = {
    left: this.scaler.scaleX(GC.sidebarWidth + window.sidebarOffset + 75),
    top: this.scaler.scaleY(326),
    bottom: this.scaler.scaleY(this.scaler.designRefHeight() - 60),
    width: this.scaler.scaleX(this.scaler.designRefWidth() - (GC.sidebarWidth + 75 + 235)),
    height: this.scaler.scaleY(this.scaler.designRefHeight() - 60*2)
  };
  if (this.scene.params.galleryId === 2) {
    this.displayBounds.left = this.scaler.scaleX(GC.sidebarWidth + window.sidebarOffset + 150)
    this.displayBounds.top = this.scaler.scaleY(232)
  }

  this.dragSprite.setScale(screenWidth, screenHeight);

  this.nameListResize();
};

Students.prototype.nameList = function() {
  var self = this;

  let screenWidth = this.scaler.width;
  let screenHeight = this.scaler.height;

  var names = this.names;

  var cellWidth = this.scaler.hScale() * this.cellWidth;
  var cellHeight = this.scaler.vScale() * this.cellWidth;
  var spacingW = this.scaler.hScale() * this.spacingW;
  var spacingH = this.scaler.vScale() * this.spacingH;

  var pannelWidth = this.displayBounds.width;
  var pannelHeight = this.displayBounds.height;

  // var cols = parseInt(pannelWidth/(cellWidth + spacingW));  

  var rows = 2;

  var cols = Math.ceil(names.length/rows);
  

  // for(var i = 0; i < this.nameSprites.length; i ++){
  //   this.nameSprites[i].destroy();
  // }


  var left = (pannelWidth - (cellWidth + spacingW) * cols + spacingW)/2;

  this.dragSprites = [];
  
  for(var i = 0; i < names.length; i ++){
    (function(i){
      var r = parseInt(i/cols);
      var c = i%cols;

      var offsetX = (cellWidth + spacingW) * c;
      var offsetY = (cellHeight + spacingH) * r;

      var avatarSprite, authorSprite, colorBgSprite, colorBgSprite2;

      var designX = left + self.displayBounds.left + offsetX;
      var designY = self.displayBounds.top + offsetY + 200;
      var displayW = 200;
      var displayH = 8;
      var color = 0xF9BB93;
      // colorBgSprite = self.createColorBg(
      //   designX, designY, displayW, displayH, color, 1
      // );

      colorBgSprite = self.scene.add.image(
        designX,
        designY,
        'point_bar'
      );
      colorBgSprite.depth = self.depthNames;
      colorBgSprite.keepData = {
        x: colorBgSprite.x,
        y: colorBgSprite.y,
        alpha: 1,
        designX: designX,
        designY: designY,
        initialY: colorBgSprite.y,
        width: 200,
        height: 8,
      };
      colorBgSprite.setOrigin(0, 0);
      self.group.add(colorBgSprite);
      self.nameSprites.push(colorBgSprite);
      self.dragSprites.push(colorBgSprite);

      colorBgSprite2 = self.scene.add.image(
        designX,
        designY,
        'point_bar'
      );
      colorBgSprite2.depth = self.depthNames;
      colorBgSprite2.keepData = {
        x: colorBgSprite2.x,
        y: colorBgSprite2.y,
        alpha: 1,
        designX: designX,
        designY: designY,
        initialY: colorBgSprite2.y,
        width: 200,
        height: 8,
      };
      colorBgSprite2.setOrigin(0, 0);
      self.group.add(colorBgSprite2);
      self.nameSprites.push(colorBgSprite2);
      self.dragSprites.push(colorBgSprite2);

      // var designX = left + self.displayBounds.left + offsetX + 42 * self.scaler.vScale();
      // var designY = self.displayBounds.top + offsetY;
      // var frame = 0;
      // if(window.custom.selected.galleryType == 'en'){
      //   frame = 1;
      // }
      // avatarSprite = self.scene.add.image(
      //   designX,
      //   designY,
      //   names[i].avatar,
      //   frame
      // );
      // avatarSprite.setOrigin(0, 0);
      // var scale_x = 311 * self.scaler.vScale() /avatarSprite.width;
      // var scale_y = 196 * self.scaler.vScale() /avatarSprite.height;
      // avatarSprite.setScale(scale_x, scale_y);
      // avatarSprite.depth = self.depthNames;
      // self.group.add(avatarSprite);
      // self.nameSprites.push(avatarSprite);
      // self.dragSprites.push(avatarSprite);
      // avatarSprite.keepData = {
      //   x: avatarSprite.x,
      //   y: avatarSprite.y,
      //   alpha: 1,
      //   initialY: avatarSprite.y,
      // };      

      var designX = left + self.displayBounds.left + offsetX;
      var designY = self.displayBounds.top + offsetY;
    
      authorSprite = self.scene.add.image(
        designX,
        designY,
        names[i].author, 0
      );
      authorSprite.setOrigin(0, 0);
      self.scaler.scaleSprite(authorSprite);
      authorSprite.depth = self.depthNames;
      self.group.add(authorSprite);
      self.nameSprites.push(authorSprite);
      self.dragSprites.push(authorSprite);
      authorSprite.keepData = {
        x: authorSprite.x,
        y: authorSprite.y,
        alpha: 1,
        initialY: authorSprite.y,
      };

      [authorSprite].map(sprite=>{
        sprite.setInteractive({ draggable: true });
        sprite.on("pointerdown", pointer => {
          if(self.dragMaxOffset < 5){
            // avatarSprite.alpha = 0.5;
            authorSprite.alpha = 0.5;
            // colorBgSprite.alpha = 0.5;
          }
        });
        sprite.on("pointerup", pointer => {
          if(self.dragMaxOffset < 5){
            self.hide();
            utils.setCookie('locator', names[i].region.json.meta.locator);
            self.viewer.jumpTo(names[i].region);
          }
        });
        // set drag events
        sprite.on('drag', function (pointer, dragX, dragY) {
          if(self.scaler.getDirection() == 'Horizontal'){
            let offset = dragY - sprite.keepData.y;
            (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));
            self.emit('drag-students', {offset: offset});
          } else{
            let offset = -(dragX - sprite.keepData.x);
            (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));
            self.emit('drag-students', {offset: offset});
          }
        }, this);
  
        sprite.on('dragstart', function (pointer, dragX, dragY) {
          //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
          self.dragMaxOffset = 0;
          sprite.keepData.y = sprite.y;
          self.emit('dragstart-students');
        }, this);
  
        sprite.on('dragend', function (pointer, dragX, dragY) {
          self.emit('dragend-students');
        }, this);
  
        sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
          self.emit('wheel-students', {offset: deltaY * 0.4});
        }, this);
      });
      
      // if(self.viewer.current && self.names[i].region.name == self.viewer.current.name){
      //   avatarSprite.alpha = 0.5;
      //   authorSprite.alpha = 0.5;
      //   colorBgSprite.alpha = 0.5;

      //   avatarSprite.keepData.alpha = 0.5;
      //   authorSprite.keepData.alpha = 0.5;
      //   colorBgSprite.keepData.alpha = 0.5;
      // }
    }(i));
  }
};

Students.prototype.nameListResize = function() {
  if(this.nameSprites.length <= 0)
    return;
  let screenWidth = this.scene.cameras.main.worldView.width;
  let screenHeight = this.scene.cameras.main.worldView.height;

  var cellWidth = this.scaler.vScale() * this.cellWidth;
  var cellHeight = this.scaler.vScale() * this.cellHeight;
  var spacingW = this.scaler.vScale() * this.spacingW;
  var spacingH = this.scaler.vScale() * this.spacingH;

  var pannelWidth = this.displayBounds.width + spacingW;
  var pannelHeight = this.displayBounds.height;

  var cols = parseInt(pannelWidth/(cellWidth + spacingW));

  var rows = Math.ceil(this.names.length/cols);

  this.cols = cols;
  this.rows = rows;

  for(var i = 0; i < this.names.length; i ++){
    var r = parseInt(i/cols);
    var c = i%cols;

    var offsetX = (cellWidth + spacingW) * c;
    var offsetY = (cellHeight + spacingH) * r;

    var colorBgSprite = this.nameSprites[i * 3 + 0];
    var colorBgSprite2 = this.nameSprites[i * 3 + 1];
    var authorSprite = this.nameSprites[i * 3 + 2];

    colorBgSprite.x = this.displayBounds.left + offsetX;
    colorBgSprite.y = this.displayBounds.top + offsetY + this.scaler.scaleY(200);
    this.scaler.scaleSpriteByHeight(colorBgSprite);
    colorBgSprite.displayWidth = this.scaler.scaleX(colorBgSprite2.keepData.width);
    colorBgSprite.displayHeight =this.scaler.scaleY( colorBgSprite2.keepData.height);

    colorBgSprite2.x = this.displayBounds.left + offsetX + this.scaler.scaleY(220);
    colorBgSprite2.y = this.displayBounds.top + offsetY + this.scaler.scaleY(200);
    this.scaler.scaleSpriteByHeight(colorBgSprite2);
    colorBgSprite2.displayWidth = this.scaler.scaleX(colorBgSprite2.keepData.width);
    colorBgSprite2.displayHeight =this.scaler.scaleY( colorBgSprite2.keepData.height);

    // avatarSprite.x = this.displayBounds.left + offsetX;
    // avatarSprite.y = this.displayBounds.top + offsetY;
    // var scale_x = 314 * this.scaler.vScale() /avatarSprite.width;
    // var scale_y = 380 * this.scaler.vScale() /avatarSprite.height;
    // avatarSprite.setScale(scale_x, scale_y);

    authorSprite.x = this.displayBounds.left + offsetX;// + avatarSprite.getBounds().width/2;
    authorSprite.y = this.displayBounds.top + offsetY;
    this.scaler.scaleSprite(authorSprite);

    // colorBgSprite.keepData.initialY = colorBgSprite.y;
    // avatarSprite.keepData.initialY = avatarSprite.y;
    authorSprite.keepData.initialY = authorSprite.y;
  }

};

Students.prototype.setCurrent = function() {
  if(this.nameSprites.length <= 0)
    return;

  for(var i = 0; i < this.names.length; i ++){
    var colorBgSprite = this.nameSprites[i * 3 + 0];
    var colorBgSprite2 = this.nameSprites[i * 3 + 1];
    var authorSprite = this.nameSprites[i * 3 + 2];
    
    if(this.viewer.current && this.names[i].region.name == this.viewer.current.name){
      // avatarSprite.alpha = 0.5;
      authorSprite.alpha = 0.5;
      colorBgSprite.alpha = 0.5;
      colorBgSprite2.alpha = 0.5;

      // avatarSprite.keepData.alpha = 0.5;
      authorSprite.keepData.alpha = 0.5;
      colorBgSprite.keepData.alpha = 0.5;
      colorBgSprite2.keepData.alpha = 0.5;
    }else{
      // avatarSprite.alpha = 1;
      authorSprite.alpha = 1;
      colorBgSprite.alpha = 1;
      colorBgSprite2.alpha = 1;

      // avatarSprite.keepData.alpha = 1;
      authorSprite.keepData.alpha = 1;
      colorBgSprite.keepData.alpha = 1;
      colorBgSprite2.keepData.alpha = 1;
    }
  } 
};

Students.prototype.hide = function() {
  // this.hideAnimation();
  this.group.setVisible(false);
};

Students.prototype.show = function() {
  try {
    this.group.setVisible(true);
  }
  catch(err) {
    console.log(err);
  }

  // this.showAnimation();
};

Students.prototype.showAnimation = function() {
  this.group.getChildren().forEach(sprite => {
    var targetAlpha = 1;
    if(sprite.keepData.alpha != undefined){
      targetAlpha = sprite.keepData.alpha;
    }
    this.scene.tweens.add({
      targets: sprite,
      alpha: {
        start: 0,
        to: targetAlpha
      },
      duration: 300,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onComplete: function () {
      },
    }); 
  });
};

Students.prototype.hideAnimation = function() {
  this.group.getChildren().forEach(sprite => {
    this.scene.tweens.add({
      targets: sprite,
      alpha: {
        start: 1,
        to: 0
      },
      duration: 300,
      delay: 0,
      ease: 'Linear',
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        this.targets[0].visible = false;
      },
    }); 
  });
};

Students.prototype.createColorBg = function(x, y, width, height, color, alpha){
  var rect = this.scene.add.rectangle(
    x,
    y,
    width,
    height
    , color, alpha);
  rect.setOrigin(0, 0);
  this.scaler.scaleSpriteByHeight(rect);
  return rect;
};

Students.prototype.createDragSprite = function () {
  var self = this;
  let dragSprite = this.createColorBg(0, 0, this.scaler.width, this.scaler.height, 0xff0000, 0);
  dragSprite.setInteractive({ draggable: true });
  // dragSprite.setOrigin(0, 0);
  // dragSprite.alpha = 0.5;
  dragSprite.keepData = { x: 0, y: 0};
  dragSprite.depth = this.depthNames - 1;

  this.dragSprite = dragSprite;
  this.dragSprite.setScale(this.scaler.width, this.scaler.height);

  this.group.add(this.dragSprite);

  dragSprite.on('dragstart', function (pointer, dragX, dragY) {
    // console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragSprite.keepData.y = this.dragSprite.y;
    this.emit('dragstart-students');
  }, this);

  dragSprite.on('drag', function (pointer, dragX, dragY) {
    //console.log(dragX);
    // check whether out area
    if(self.scaler.getDirection() == 'Horizontal'){
      this.emit('drag-students', {offset: dragY - this.dragSprite.keepData.y});
    } else{
      this.emit('drag-students', {offset: -(dragX - this.dragSprite.keepData.x)});
    }

  }, this);

  dragSprite.on('dragend', function (pointer, dragX, dragY) {
    // console.log('drag end');
    this.emit('dragend-students');
  }, this);

  dragSprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    this.emit('wheel-students', {offset: deltaY * 0.4});
  }, this);


};

Students.prototype.checkEnding = function () {
  var min, max;
  if(this.dragSprites.length <= 0)
    return;
  min = this.dragSprites[0 * 3 + 2].y;
  max = this.dragSprites[Math.ceil(this.names.length - 1) * 3 + 1].y;

  if(min >= this.displayBounds.top && max < this.displayBounds.top + this.displayBounds.height - (this.cellHeight + 60) * this.scaler.vScale()){
    this.allowDown = false;
    this.allowUp = false;
    return;
  }

  if(min > this.displayBounds.top){
    this.allowDown = false;

    var offset = min - this.displayBounds.top;
    this.dragSprites.map(sprite => {
      sprite.y -= offset; 
    });

  } else{
    this.allowDown = true;
  }

  if(max < this.displayBounds.top + this.displayBounds.height - (this.cellHeight + 60) * this.scaler.vScale()){
    this.allowUp = false;

    if(min < this.displayBounds.top){
      var offset = this.displayBounds.top + this.displayBounds.height - (this.cellHeight + 60) * this.scaler.vScale() - max;
      this.dragSprites.map(sprite => {
        sprite.y += offset; 
      });
    }

  } else{
    this.allowUp = true;
  }
  
  // if(this.lastUp != this.allowUp){
  //   this.lastUp = this.allowUp;
  //   console.log("allowUp=" + this.allowUp);
  //   console.log("max=" + max);
  // }
  // if(this.lastDown != this.allowDown){
  //   this.lastDown = this.allowDown;
  //   console.log("allowDown=" + this.allowDown);
  // }
  // console.log("allowDown=" + this.allowDown);
  // console.log("allowUp=" + this.allowUp);

};

Students.prototype.checkCrop = function () {
  if(this.dragSprites.length <= 0){
    return;
  }

  let top = 320;
  const bottom = 0;
  let start, end;
  if (this.scene.params.galleryId === 2) {
    top = 232;
  }
  if(this.scaler.scaleMode === 'full') {
    start = this.scaler.scaleBgY(top);
    end = this.scaler.scaleBgY(bottom, 'bottom');
  } else {
    start = this.scaler.scaleY(top);
    end = this.scaler.scaleY(this.scaler.designRefHeight() + bottom);
  }

  this.dragSprites.forEach(sprite => {
    if(sprite.type == 'Image' || sprite.type == 'Text') {
      let spriteStart, spriteEnd;
      var scale = 1;
      if(sprite.keepData.scale != undefined) {
        scale = sprite.keepData.scale;
      }
      if(sprite.originY == 0) {
        spriteStart = sprite.y;
        spriteEnd = sprite.y + sprite.displayHeight;
      } else if(sprite.originY == 0.5) {
        spriteStart = sprite.y - sprite.displayHeight/2;
        spriteEnd = sprite.y + sprite.displayHeight/2;
      } else if(sprite.originY == 1) {
        spriteStart = sprite.y - sprite.displayHeight;
        spriteEnd = sprite.y;
      }
      
      if(spriteStart < start) {
        var y = this.scaler.getDesignY(start - spriteStart) /scale;
        var cropH = sprite.height - y;
        sprite.setCrop(0, y, sprite.width, cropH);
      } else if(spriteEnd > end){
        var y = 0;
        var cropH = sprite.height - this.scaler.getDesignY(spriteEnd - end)/scale;
        sprite.setCrop(0, y, sprite.width, cropH);
      } else{
        sprite.setCrop(0, 0, sprite.width, sprite.height);
      }
    }
  });
};

Students.prototype.dragScrollerbar = function(){
  if(this.scrollerbarSprite.y < this.scaler.scaleY(this.scrollerBarBounds.top)){
    this.scrollerbarSprite.y = this.scaler.scaleY(this.scrollerBarBounds.top);
  }
  if(this.scrollerbarSprite.y > this.scaler.scaleY(this.scrollerBarBounds.bottom - this.scrollerbarSprite.height)){
    this.scrollerbarSprite.y = this.scaler.scaleY(this.scrollerBarBounds.bottom - this.scrollerbarSprite.height);
  }

  var maxY = this.scaler.scaleY(this.scrollerBarBounds.top) + this.scaler.vScale() * ((380 + 50) * this.rows - 50);

  var bottom = this.scaler.scaleY(this.scrollerBarBounds.bottom);

  var totalH = (this.scrollerBarBounds.bottom - this.scrollerBarBounds.top - this.scrollerbarSprite.height) * this.scaler.vScale();
  var currentH = this.scrollerbarSprite.y - this.scaler.scaleY(this.scrollerBarBounds.top);
  
  var progress = currentH/totalH;

  // console.log(progress);

  var offset = (maxY - bottom)*progress;
  this.dragSprites.map(sprite => {
    sprite.y = sprite.keepData.initialY - offset; 
  });
};

Students.prototype.updateScrollerbar = function(){
  // if(this.enableScroll() == false){
  //   return;
  // }

  var bottom = this.scaler.scaleY(this.scrollerBarBounds.bottom);

  var maxY = this.scaler.scaleY(this.scrollerBarBounds.top) + this.scaler.vScale() * ((380 + 50) * this.rows - 50);

  var totalHeight = maxY - bottom;
  var currentH = this.dragSprites[this.dragSprites.length - 1].y + 380 - bottom;

  if(totalHeight < 0){
    this.scrollerbarSprite.visible = false;
  } else{
    this.scrollerbarSprite.visible = true;
  }

  var progress = 1 - currentH/totalHeight;
  // console.log(progress);

  var designY = this.scrollerBarBounds.top + progress * (this.scrollerBarBounds.bottom - this.scrollerBarBounds.top - this.scrollerbarSprite.height);
  if(designY < this.scrollerBarBounds.top){
    designY = this.scrollerBarBounds.top;
  }
  if(designY > (this.scrollerBarBounds.bottom - this.scrollerbarSprite.height)){
    designY = this.scrollerBarBounds.bottom - this.scrollerbarSprite.height;
  }
  this.scrollerbarSprite.y = this.scaler.scaleY(designY);
};

export default Students;