"use strict";

import scaler from "./scaler";
import DragSprite from "./drag-sprite";
import Region from "./region";
import GC from "./const";
import UIController from "./ui-controller";
import Students from "./students";

let viewer;

function Viewer() {
  this.scaler = scaler;
  this.current;
  this.allowLeft = true;
  this.allowRight = true;
}

// mode: 'single' and 'multiple'
Viewer.prototype.init = function (regionsContent, theme, speeds, mode) {
  this.theme = theme || 'default';
  this.speeds = speeds;
  this.mode = (mode && mode.length > 0 && mode === 'multiple') ? mode : 'single';

  // this.recalculateSize();
  // this.game = new Phaser.Game({
  //   type: Phaser.CANVAS,
  //   width: this.width,
  //   height: this.height,
  //   scale: {
  //     mode: Phaser.DOM.RESIZE
  //     // mode: Phaser.Scale.FIT,
  //     // autoCenter: Phaser.Scale.CENTER_BOTH
  //   },
  //   transparent: true,
  //   parent: 'phaser-wrapper',
  //   scene: {
  //     preload: preload,
  //     create: create,
  //     update: update
  //   }
  // });

  // Create regions
  let depthBase = 50000;
  let regionOffset = 0 ;
  let layerSpeeds = GC.layerSpeeds;
  let layerWidths = GC.layerWidths;
  let index = 0;
  this.regions = [];

  regionsContent.map(item => {
    let region;
    if (item.content && typeof item.content === 'string') {
      region = new Region(JSON.parse(item.content), scaler);
    } else if (typeof item === 'string') {
      region = new Region(JSON.parse(item), scaler);
    } else if (typeof item === 'object') {
      region = new Region(item, scaler);
    }

    if (region.hidden && this.mode === 'multiple') {
      // ignore hidden regions in multiple mode
      return;
    }

    // dedicated offset is assigned first
    if (region.showWall) {
      regionOffset += GC.dedicatedWidth;
    }

    region.setName("R" + index);
    region.setViewer(this);
    region.setOffset(regionOffset);
    region.setDepthBase(depthBase);
    region.setLayerSpeeds(layerSpeeds);
    region.setLayerWidths(layerWidths);

    // we need by pass drag event to all regions
    region.on('dragger_dragstart', function() {
      viewer.regions.map(r => r.emit('dragstart'));
    });
    region.on('dragger_drag', function(evt) {
      viewer.regions.map(r => r.emit('drag', {offset: evt.offset, speeds: evt.speeds}));
    });
    region.on('dragger_dragend', function(evt) {
      viewer.regions.map(r => r.emit('dragend'));
    });

    region.on('dragger_wheel', function(evt) {
      viewer.regions.map(r => r.emit('wheel', {offset: evt.offset, speeds: evt.speeds}));
    });

    //region.preload();
    this.regions.push(region);

    // it should ok even there is some overlap for different region
    depthBase -= 50;

    regionOffset += region.columns * GC.columnWidth;
    index ++;
    //TODO: include name wall also
    //if (region.showWall)
  });

  this.windowResized = false;

  this.dragger;
};

Viewer.prototype.recalculateSize = function() {
  this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
  this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;

  // // resize the canvas
  let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
  if (canvas) {
    canvas.width = viewer.width;
    canvas.height = viewer.height;
  }

  let designRefWidth = GC.resolution * this.width/this.height;
  let designRefHeight = GC.resolution;

  scaler.config(this.width, this.height, designRefWidth, designRefHeight);

  // viewer.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
  // viewer.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;

  // // resize the canvas
  // document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0].width = viewer.width;
  // document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0].height = viewer.height;

  // let designRefWidth = GC.resolution * viewer.width / viewer.height;
  // let designRefHeight = GC.resolution;

  // scaler.config(viewer.width, viewer.height, designRefWidth, designRefHeight);

};

Viewer.prototype.setScene = function(scene) {
  this.scene = scene;
};

Viewer.prototype.preload = function () {

  var loadingText = this.scene.add.text(
    this.scaler.width/2,
    this.scaler.height/2,
    '',
    {fontSize: '28px'}
  );
  loadingText.setOrigin(0.5, 0.5);
  loadingText.setColor('#ffffff');
  loadingText.keepData = {
    resized: true
  };


  // ui buttons
  // this.scene.load.image('btn_portfolio', require("../images/btn_portfolio.png"));

  // preload regions
  this.regions.map(region => {
    region.setScene(this.scene);
    region.preload();
  });

  // Create drag sprite
  this.dragger = new DragSprite(this.scene, this.scaler);
  this.dragger.preload();

  this.UIController = new UIController(this.scene, this.scaler, viewer, this.mode);
  this.UIController.preload();

  this.scene.load.on('progress', function (progress){
    try {
      // loadingText.setText('LOADING...' + parseInt(progress * 100) + '%');
    }
    catch(err){
      // console.log(err);
    }

    if (progress >= 1) {
      //loadingText.visible = false;
    }
  }, this);

  //students
  this.viewer = viewer;
  var names = [];
  for(var i = 0; i < this.viewer.regions.length; i ++){
    if(this.viewer.regions[i].json.hidden == false){
      if(this.viewer.regions[i].json.meta && this.viewer.regions[i].json.meta.name && this.viewer.regions[i].json.meta.name.texture
        && this.viewer.regions[i].json.meta.category != 'department') {
          var avatarTexture = 'empty';
          var authorTexture = 'empty';
          if(this.viewer.regions[i].json.meta.avatar){
            avatarTexture = this.viewer.regions[i].json.meta.avatar.texture;
          }
          if(this.viewer.regions[i].json.meta.author){
            authorTexture = this.viewer.regions[i].json.meta.author.texture;
          }

          var obj = {
            avatar: avatarTexture,
            author: authorTexture,
            region: this.viewer.regions[i]
          };

          // 除去没有目录的locator
          const key = GC.galleryMap[custom.selected.galleryId][custom.selected.lan]
          if(!(GC.ignoreLocator[key] && GC.ignoreLocator[key].includes(this.viewer.regions[i].json.meta.locator))) {
            names.push(obj);
          }
      }
    }
  }

  this.students = new Students(this.scene, this.scaler, names, this.viewer);
  this.students.preload();
};

Viewer.prototype.create = function () {
  var depthSprite = this.scene.add.rectangle(
    0,
    0,
    1,
    1
    , 0xffffff, 0);

  var sprite = this.scene.add.image(
    0,
    0,
    'bg'
  );
  sprite.setOrigin(0, 0);
  sprite.setScale(this.scaler.width/sprite.width, this.scaler.height/sprite.height);
  sprite.keepData = {
    resized: true
  };

  this.dragger.create();
  this.dragger.on('dragstart', function() {
    depthSprite.depth = 0;
    viewer.regions.map(region => region.emit('dragstart'));
  });
  this.dragger.on('drag', function(evt) {
    viewer.regions.map(region => {
      region.emit('drag', {
        offset: evt.offset,
        speeds: viewer.speeds
      });
    });
  });
  this.dragger.on('dragend', function() {
    viewer.regions.map(region => region.emit('dragend'));
  });
  this.dragger.on('wheel', function(evt) {
    viewer.regions.map(region => {
      region.emit('wheel', {
        offset: evt.offset,
        speeds: viewer.speeds
      });
    });
  });
  this.dragger.resize(this.scaler.width, this.scaler.height);

  // this.scene.scale.on('resize',function resize(gameSize, baseSize, displaySize) {
  //   viewer.recalculateSize();
  //   viewer.windowResized = true;
  // }, this);

  // create regions
  let previosRegionMagicDepth = 0;
  this.regions.map(region => {
    if (previosRegionMagicDepth) {
      region.prevMagicDepth = previosRegionMagicDepth;
    }
    region.create();
    previosRegionMagicDepth = region.magicDepth;
  });

  var designX = this.scaler.designRefWidth();
  var designY = 0;
  var sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'ending'
  );
  sprite.setOrigin(1, 0);
  this.scaler.scaleSpriteByHeight(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   designX: designX,
  //   designY: designY,
  //   x: sprite.x
  // };
  sprite.depth = 60000;
  sprite.visible = false;
  this.endingSprite = sprite;

  this.endingGroup = this.createEndingGroup();
  this.endingGroup.setVisible(false);

  this.startingGroup = this.createStartingGroup();
  this.startingGroup.setVisible(false);
  
  this.UIController.create();

  this.students.create();

  var locator = window.custom.locator;
  if(locator != null){
    for(var i = 0; i < this.regions.length; i ++){
      if(locator == this.regions[i].json.meta.locator){
        this.jumpTo(this.regions[i]);
        //update current
        this.current = this.regions[i];
        var regionIndex = parseInt(this.current.name.split('R')[1]);
        this.UIController.setCurrentRegion(regionIndex);

        this.students.setCurrent();
        break;
      }
    }
  } else {
    this.jumpToFirstRegion();
  }
};

Viewer.prototype.update = function (game) {
  // update for all draggers, including video

  let maxWidth = 0, visibleWidth;
  let maxRegion;
  this.regions.map((region) => {
    region.update();
    visibleWidth = region.getVisibleWidth();
    if (maxWidth < visibleWidth)  {
      maxWidth = visibleWidth;
      maxRegion = region;
    }
  });

  if (maxRegion && this.current != maxRegion) {
    console.log("switch to" + maxRegion.name);
    this.current = maxRegion;

    var regionIndex = parseInt(this.current.name.split('R')[1]);
    this.UIController.setCurrentRegion(regionIndex);

    this.students.setCurrent();
    
    utils.setCookie('selected', encodeURIComponent(JSON.stringify(window.custom.selected)));
    utils.setCookie('locator', this.current.json.meta.locator);
  }

  // if (this.windowResized) {
  //   game.scale.resize(this.width, this.height);
  //   //game.scale.updateBounds();
  //   this.resized();
  //   this.windowResized = false;
  // }

  //console.log(`${this.isTouchingEnd()} ${this.isTouchingStart()}`);
  this.UIController.update();

  this.students.update();

  if(this.isTouchingEnd() == true){
    this.allowLeft = false;
    this.showAnimation(this.endingGroup);
    // this.endingSprite.visible = true;
    
  } else{
    this.allowLeft = true;
    this.hideAnimation(this.endingGroup);
    // this.endingSprite.visible = false;
  }

  if(this.isTouchingStart() == true){
    this.allowRight = false;
    // this.showAnimation(this.startingGroup);
  } else{
    this.allowRight = true;
    this.hideAnimation(this.startingGroup);
  }

  if(this.current.loadState == GC.LOADING || this.current.loadState == GC.LOADED){
    let progress = this.current.loadingProgress();
    this.scene.scene.get('sceneLoading').setLoadingProgress(Math.floor(progress * 100));
    // this.scene.scene.get('sceneGuide').startLoadingAnimation();  
  }
  if(this.current.createState == GC.CREATED){
    this.scene.scene.get('sceneLoading').closeLoadingMask();
    
    // this.scene.scene.get('sceneGuide').stopLoadingAnimation();  

    if(window.custom.noTip != true && this.scene.scene.get('sceneMain')){
      this.scene.scene.get('sceneMain').firstRegionLoaded = true;
    }
  }

  if(this.current.loadState == GC.UNLOAD && this.current.createState == GC.DESTROYED){
    this.scene.scene.get('sceneLoading').closeLoadingMask();

    if(window.custom.noTip != true && this.scene.scene.get('sceneMain')){
      this.scene.scene.get('sceneMain').firstRegionLoaded = true;
    }
  }

  if(this.current.portfolio.loadState == GC.LOADED){
    let progress = this.current.portfolio.loadingProgress();
    this.scene.scene.get('sceneLoading').setLoadingProgress(Math.floor(progress * 100));
    // this.scene.scene.get('sceneGuide').startLoadingAnimation();  
  }

  if(this.current.portfolio.createState == GC.CREATED){
    this.scene.scene.get('sceneLoading').closeLoadingMask();
  }

  // if(this.current.portfolio.loadState == GC.UNLOAD && this.current.portfolio.createState == GC.DESTROYED){
  //   this.scene.scene.get('sceneGuide').stopLoadingAnimation();
  // }

};

Viewer.prototype.createEndingGroup = function() {
  var designX, designY, sprite;

  var group = this.scene.add.group();

  designX = 0;
  designY = 0;
  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    this.scaler.designRefWidth(),
    this.scaler.designRefHeight()
    , 0x000000, 0);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteFill(sprite);
  sprite.keepData = {
    resized: true,
    fill: true,
    xlocation: 'center',
    ylocation: 'center',
    designX: designX,
    designY: designY,
  };
  sprite.depth = 60000;
  group.add(sprite);

  designX = 0;
  designY = 0;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth()),
    this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    'gallery_shadow_bg_' + this.scene.params.galleryId
  );
  sprite.setOrigin(1, 0.5);
  this.scaler.scaleSpriteFill(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    ylocation: 'center',
    designX: designX,
    designY: designY,
    fill: true,
  };
  sprite.depth = 60000;
  group.add(sprite);

  let lan = 'cn';
  if(window.custom.selected.lan) {
    lan = window.custom.selected.lan;
  }
  designX = -80;
  designY = 0;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    'ending_' + lan
  );
  sprite.setOrigin(1, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    ylocation: 'center',
    designX: designX,
    designY: designY,
  };
  sprite.depth = 60000;
  group.add(sprite);

  return group;
};

Viewer.prototype.createStartingGroup = function() {
  var designX, designY, sprite;

  var group = this.scene.add.group();

  designX = 0;
  designY = 0;
  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    this.scaler.designRefWidth(),
    this.scaler.designRefHeight()
    , 0x000000, 1);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteFill(sprite);
  sprite.keepData = {
    resized: true,
    fill: true,
    xlocation: 'center',
    ylocation: 'center',
    designX: designX,
    designY: designY
  };
  sprite.depth = 60000;
  group.add(sprite);

  designX = 186 + window.needOffset;
  designY = 0;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    'text_starting'
  );
  sprite.setOrigin(0, 0);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    ylocation: 'center',
    designX: designX,
    designY: designY,
    offset: 'x',
    initialX: 186,
  };
  sprite.depth = 60000;
  group.add(sprite);

  return group;
};

Viewer.prototype.showAnimation = function(group) {
  var self = this;
  var duration = 400;
  
  group.setVisible(true);
  // if(this.showEndingTween) {
  //   this.showEndingTween.remove();
  // }
  // if(this.hideEndingTween) {
  //   this.hideEndingTween.remove();
  // }
  this.showEndingTween = this.scene.tweens.add({
    targets: group.getChildren(),
    alpha: {
      start: 0,
      to: 1
    },
    duration: duration,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    yoyo: false,
    onComplete: function () {
    },
  });
};


Viewer.prototype.hideAnimation = function(group) {
  var self = this;
  var duration = 400;
  
  this.hideEndingTween = this.scene.tweens.add({
    targets: group.getChildren(),
    alpha: 0,
    duration: duration,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    yoyo: false,
    onComplete: function () {
      group.setVisible(false);
    },
  });
};

Viewer.prototype.isTouchingEnd = function () {
  let speeds = this.speeds;
  let lastRegion = this.regions[this.regions.length - 1];
  let touching = false;
  if (lastRegion) {
    // we use first layer for touching decision
    let offset = (lastRegion.endAnchorSprite.x)/speeds[1] * speeds[3];
    // console.log(`${offset} ${this.width}`);

    // if (offset <= scaler.scaleX(scaler.designRefWidth())/2) {
    if (offset <= 0) {

      let offset = (0 - lastRegion.endAnchorSprite.x)/speeds[1] * speeds[3];
      // this.regions.map(region => {
      //   region.emit('wheel', {
      //     offset: offset,
      //     speeds: this.speeds
      //   });
      // });

      touching = true;
    }
  }
  return touching;
};

Viewer.prototype.isTouchingStart = function () {
  let speeds = this.speeds;
  let lastRegion = this.regions[0];
  let touching = false;
  if (lastRegion) {
    // we use first layer for touching decision
    let startX = (lastRegion.startAnchorSprite.x);
    // let offset = (lastRegion.startAnchorSprite.x - 1920)/speeds[1] * speeds[3];
    // console.log(`${offset} ${this.width}`);

    if (startX >= scaler.scaleX(0 + GC.sidebarWidth)) {

      // let offset = (scaler.scaleX(scaler.designRefWidth())*0.8 - lastRegion.startAnchorSprite.x + 1920)/speeds[1] * speeds[3];
      let offset = (startX - scaler.scaleX(0 + GC.sidebarWidth))/speeds[1];
      this.regions.map(region => {
        region.emit('wheel', {
          offset: -offset,
          speeds: this.speeds
        });
      });

      touching = true;
    }
  }
  return touching;
};

Viewer.prototype.jumpTo = function (targetRegion) {
  if (!targetRegion || !targetRegion.name) { return; }

  let regionIndex = parseInt(targetRegion.name.split('R')[1]);
  let speeds = this.speeds;
  let offset = (0 - targetRegion.startAnchorSprite.x)/speeds[1] * speeds[3];

  //移动到侧边栏右边，之前adjustOffset的功能
  offset += scaler.scaleX(window.sidebarOffset + GC.sidebarWidth)/speeds[1] * speeds[3];

  if (regionIndex >= 0) {

    // simulate wheel action for jumping
    this.regions.map(region => {
      region.emit('wheel', {
        offset: offset,
        speeds: this.speeds,
        ignore: true
      });
    });

  }

  // this.adjustOffset();
};

Viewer.prototype.jumpToFirstRegion = function () {
  // console.log('jump to first region..');
  var self = this;
  if(this.regions && this.regions[0] && this.current && this.regions[0].startAnchorSprite) {
    this.current = this.regions[0];
    this.jumpTo(this.current);
  } else {
    this.jumpTimer = setTimeout(function() {
      self.jumpToFirstRegion();
    }, 100);
  }
  
  window.custom.locator = null;
};


Viewer.prototype.resized = function () {
  if(!this.scene)
    return;
  // resize sprite
  var phaserElements =this.scene.children.list;
  for(var i = 0; i < phaserElements.length; i ++){
    var s = phaserElements[i];
    if(s.keepData && s.keepData.resized == true){
      if(s.keepData.offset == 'x') {
        s.keepData.designX = s.keepData.initialX + window.sidebarOffset;
      }

      if(s.texture && s.texture.key == 'bg'){
        scaler.scaleSpriteFill(s);

        // s.setScale(scaler.scaleX(scaler.designRefWidth())/s.width, scaler.scaleY(scaler.designRefHeight())/s.height);
      } else{
        if(s.keepData.designX != undefined){
          if(s.keepData.xlocation && s.keepData.xlocation == 'right'){
            s.x = scaler.scaleX(scaler.designRefWidth() + s.keepData.designX);
          } else if(s.keepData.xlocation && s.keepData.xlocation == 'center'){
            s.x = scaler.scaleX(scaler.designRefWidth()/2 + s.keepData.designX);
          } else{
            s.x = scaler.scaleX(s.keepData.designX);
          }
        }
        
        if(s.keepData.designY != undefined){
          if(s.keepData.ylocation && s.keepData.ylocation == 'bottom'){
            s.y = scaler.scaleY(scaler.designRefHeight() + s.keepData.designY);
          } else if(s.keepData.ylocation && s.keepData.ylocation == 'center'){
            s.y = scaler.scaleY(scaler.designRefHeight()/2 + s.keepData.designY);
          } else{
            s.y = scaler.scaleY(s.keepData.designY);
          }
        }

        if(s.keepData.fill){
          scaler.scaleSpriteFill(s);
        } else if(s.keepData.size){
          try {
            s.setDisplaySize(scaler.vScale() * s.keepData.size.width, scaler.vScale() * s.keepData.size.height);
          } catch(err) {
            // console.log(err)
          }
        }else if(s.keepData.scaleByMax){
          this.scaler.scaleSpriteByMax(s);
        }else{
          scaler.scaleSpriteByHeight(s);
          // check whether user customized scale factor is set
          if (s.keepData.scaleFactor) {
            s.setScale(s.scaleX * s.keepData.scaleFactor, s.scaleY * s.keepData.scaleFactor);
          }
        }

        if(s.keepData.scale) {
          s.scale *= s.keepData.scale;
        }
      }

    }
  }

  this.endingSprite.x = this.scaler.scaleX(this.scaler.designRefWidth());
  this.endingSprite.y = this.scaler.scaleY(0);
  this.scaler.scaleSpriteByHeight(this.endingSprite);

  // resize dragger
  viewer.dragger.resize(this.scaler.scaleX(this.scaler.designRefWidth()), this.scaler.scaleY(this.scaler.designRefHeight()));

  // please resize the mouse pointer
  // mouseSprite

  // resize regions
  viewer.regions.map(r => (r.resize && r.resize()));

  // this.UIController.resize();

  // jumping to current range
  this.jumpTo(this.current);

  this.students.resize();
};

Viewer.prototype.adjustOffset = function () {
  viewer.regions.map(region => {
    region.emit('wheel', {
      offset: scaler.scaleX(window.sidebarOffset + GC.sidebarWidth)/viewer.speeds[1],
      speeds: viewer.speeds
    });
  });
};

function preload ()
{
  viewer.setScene(this);
  viewer.preload();
}

function create ()
{
  viewer.create();
}

function update(){
  viewer.update();

  // need take care of performance
  /*
  if(document.getElementsByClassName('revert')[0].innerHTML == 'Revert'){
    document.getElementsByClassName('revert')[0].innerHTML = '恢复为默认值';
  }
  if(document.getElementsByClassName('close-button')[0].innerHTML == 'Close Controls'){
    document.getElementsByClassName('close-button')[0].innerHTML = '关闭控制面板';
  }

  if(document.getElementsByClassName('close-button')[0].innerHTML == 'Open Controls'){
    document.getElementsByClassName('close-button')[0].innerHTML = '打开控制面板';
  }
  */

}

viewer = new Viewer();

Zepto(function($) {
  // copy from https://developer.mozilla.org/zh-CN/docs/Web/API/Element/wheel_event

  var prefix = "", _addEventListener, onwheel, support;
  // detect event model
  if ( window.addEventListener ) {
    _addEventListener = "addEventListener";
  } else {
    _addEventListener = "attachEvent";
    prefix = "on";
  }

  // detect available wheel event
  support = "onwheel" in document.createElement("div") ? "wheel" : // 各个厂商的高版本浏览器都支持"wheel"
    document.onmousewheel !== undefined ? "mousewheel" : // Webkit 和 IE一定支持"mousewheel"
    "DOMMouseScroll"; // 低版本firefox

  window.addWheelListener = function( elem, callback, useCapture ) {
    _addWheelListener( elem, support, callback, useCapture );

    // handle MozMousePixelScroll in older Firefox
    if( support == "DOMMouseScroll" ) {
      _addWheelListener( elem, "MozMousePixelScroll", callback, useCapture );
    }
  };

  function _addWheelListener( elem, eventName, callback, useCapture ) {
    elem[ _addEventListener ]( prefix + eventName, support == "wheel" ? callback : function( originalEvent ) {
      !originalEvent && ( originalEvent = window.event );

      // create a normalized event object
      var event = {
        // keep a ref to the original event object
        originalEvent: originalEvent,
        target: originalEvent.target || originalEvent.srcElement,
        type: "wheel",
        deltaMode: originalEvent.type == "MozMousePixelScroll" ? 0 : 1,
        deltaX: 0,
        deltaZ: 0,
        preventDefault: function() {
          originalEvent.preventDefault ?
          originalEvent.preventDefault() :
          originalEvent.returnValue = false;
        }
      };

      // calculate deltaY (and deltaX) according to the event
      if ( support == "mousewheel" ) {
        event.deltaY = - 1/40 * originalEvent.wheelDelta;
        // Webkit also support wheelDeltaX
        originalEvent.wheelDeltaX && ( event.deltaX = - 1/40 * originalEvent.wheelDeltaX );
      } else {
        event.deltaY = originalEvent.detail;
      }

      // it's time to fire the callback
      return callback( event );
    }, useCapture || false );
  }

  addWheelListener(document.querySelector("#phaser-wrapper"), function(e){
    //console.log( e.deltaY );
    e.preventDefault();
  });

});

export default viewer;




