'use strict';

var SceneGalleryInfo = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneGalleryInfo ()
  {
    Phaser.Scene.call(this, { key: 'sceneGalleryInfo', active: false });

    this.currentLan = 'cn';
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;

    if(this.params.prefaceLan) {
      this.currentLan = this.params.prefaceLan;
    }
  },

  preload: function ()
  {

  },

  create: function ()
  {
    if(this.params.hide) {
      this.hide();
    }

    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'gallery_preface_bg' + '_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    if (this.params.galleryId === 1) {
      designX = 320;
      designY = 109;
    } else {
      designX = 357;
      designY = 130;
    }
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'gallery_info_' + this.currentLan + '_' + this.params.galleryId
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
    };
    this.galleryInfoSprite = sprite;

    if(utils.hasLanguageButton(this.params.galleryId)) {
      designX = 168;
      designY = 762.5;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX),
        this.scaler.scaleY(designY),
        'gallery_btn_lan_preface_' + this.currentLan + '_' + this.params.galleryId
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY,
        scaleBgX: true,
        enableClick: true,
      };
      this.btnLanSprite = sprite;
      if (this.params.galleryId === 1) {
        sprite.setVisible(false);
      }
      
      sprite.setInteractive();
      
      sprite.on("pointerdown", pointer => {
        this.btnLanSprite.isPressed = true;
        this.btnLanSprite.alpha = 0.5;
      });

      sprite.on("pointerup", pointer => {
        if(this.btnLanSprite.isPressed) {
          this.changeLanguage()
        }
        this.btnLanSprite.isPressed = false;
        this.btnLanSprite.alpha = 1;
      });
    }

    designX = 168;
    designY = 942;
    if (this.params.galleryId === 2) {
      designX = 76;
      designY = 934;
    }
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'gallery_btn_back_preface_' + this.params.galleryId
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    
    if (this.params.galleryId === 1) {
      designX = -243;
      designY = 891;  
    } else if(this.params.galleryId === 2) {
      designX = -130;
      designY = 491;  
    } else {
      designX = -120;
      designY = 282;
    }
    let source = 'gallery_btn_next_' + this.params.galleryId;
    if (this.params.galleryId === 0) {
      source = 'gallery_btn_enter_cn_' + this.params.galleryId
    }
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      source
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnEnterCNSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnEnterCNSprite.isPressed = true;
      btnEnterCNSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnEnterCNSprite.isPressed) {
        this.toGallery('cn', this.currentLan);
      }
      btnEnterCNSprite.isPressed = false;
      btnEnterCNSprite.alpha = 1;
    });

    if (this.params.galleryId === 0) {
      designX = -120;
      designY = 787;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX, 'right'),
        this.scaler.scaleY(designY),
        'gallery_btn_enter_en_' + this.params.galleryId
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        xlocation: 'right',
        designX: designX,
        designY: designY,
        scaleBgX: true,
        enableClick: true,
      };
      if (this.params.galleryId === 1) {
        sprite.setVisible(false);
      }
  
      const btnEnterENSprite = sprite;
      
      sprite.setInteractive();
      
      sprite.on("pointerdown", pointer => {
        btnEnterENSprite.isPressed = true;
        btnEnterENSprite.alpha = 0.5;
      });
  
      sprite.on("pointerup", pointer => {
        if(btnEnterENSprite.isPressed) {
          this.toGallery('en', this.currentLan);
        }
        btnEnterENSprite.isPressed = false;
        btnEnterENSprite.alpha = 1;
      });
    }



    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
      });
    }
  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  toGallery: function(lan, prefaceLan) {
    this.outAnimation();
    utils.toGallery(this.scene, this.params.galleryId, lan, prefaceLan);
  },

  changeLanguage: function() {
    if(this.currentLan === 'cn') {
      this.currentLan = 'en';
    } else {
      this.currentLan = 'cn';
    }

    this.galleryInfoSprite.setTexture('gallery_info_' + this.currentLan + '_' + this.params.galleryId);
    this.btnLanSprite.setTexture('gallery_btn_lan_preface_' + this.currentLan + '_' + this.params.galleryId);
  },


  back: function() {
    this.scene.start('scenePreface', {
      galleryId: this.params.galleryId,
      animation: false,
    });
  },

  outAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.animationing = false;
      self.hide();
    });
  },

  show: function() {
    this.cameras.main.alpha = 1;
    this.scene.setVisible(true);
    this.scene.bringToTop();
  },

  hide: function() {
    this.scene.setVisible(false);
    this.scene.sendToBack();
  },
});
export default SceneGalleryInfo;