"use strict";

let GC = {
  resolution: 1080,

  dedicatedWidth: (6900 - 2.0) * (3 / 6) ,

  columnWidth: (6900 - 2.0) * 1,

  regionWidth: (6900 - 2.0) * 2,

  layerSpeeds: [(6900 - 2.0)/(1478 - 2.0), (3104 - 2.0)/(1478 - 2.0), (2000 - 2.0)/(1478 - 2.0), (1478 - 2.0)/(1478 - 2.0)],

  layerWidths: [ (6900 - 2.0), (3104 - 2.0), (2000 - 2.0), (1478 - 2.0) ],

  UNLOAD: 'unload',
  LOADING: 'loading',
  LOADED: 'loaded',

  DESTROYED: 'destroyed',
  CREATED: 'created',

  sidebarWidth: 150,

  animationDuration: 800,

  galleryMap: {
    0: {
      cn: 'b92ed30f', // 双校记：透过显微镜看哈佛与清华
      en: 'ee4143ef', // A Tale of Two Universities：Harvard and Tsinghua through the Microscope
    },
    1: {
      cn: '1663379c', // 拉瓦锡诞辰280周年纪念展
      en: '00d666dc', // Lavoisier 280th Anniversary Exhibition
    },
    2: {
      cn: '0f30c8bf', // 跨越N次方
      en: '0f30c8bf', // 跨越N次方
    },
  },

  ignoreLocator: {
    // // 跨越N次方
    // '0f30c8bf': [
    //   'df368884', // 前言
    //   '433531fd', // 结语
    // ]
  }
};


export default GC;

//console.log(GC);
