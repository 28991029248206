'use strict';

import textLoadingImg from '../images/text_loading.png';
import bgHomeImg from '../images/bg_home.png';
import ballImg0 from '../images/ball/0.png';
import ballImg1 from '../images/ball/1.png';
import ballImg2 from '../images/ball/2.png';
import ballImg3 from '../images/ball/3.png';
import ballImg4 from '../images/ball/4.png';
import lightImg from '../images/light.png';
import titleImg from '../images/title.png';
// import copyrightImg from '../images/copyright.png';
import btnEnterImg from '../images/btn_enter.png';
import homeTextImg from '../images/home_text.png';

import galleryBtnBackPrafaceImg0 from '../images/galleries/0/btn_back_preface.png';
import galleryBtnBackPrafaceImg1 from '../images/galleries/1/btn_back_preface.png';
import galleryBtnBackPrafaceImg2 from '../images/galleries/2/btn_back_preface.png';

import galleryBtnLanPrefaceCNImg0 from '../images/galleries/0/btn_lan_preface_cn.png';
import galleryBtnLanPrefaceENImg0 from '../images/galleries/0/btn_lan_preface_en.png';
import galleryBtnLanPrefaceCNImg1 from '../images/galleries/1/btn_lan_preface_cn.png';
import galleryBtnLanPrefaceENImg1 from '../images/galleries/1/btn_lan_preface_en.png';


import bgMenuImg from '../images/bg_menu.png';
import btnBackWhiteImg from '../images/btn_back_white.png';
import mainExhibitionImg from '../images/main_exhibition.png';

import galleryInfoCNImg0 from '../images/galleries/0/info_cn.png';
import galleryInfoENImg0 from '../images/galleries/0/info_en.png';
import galleryInfoCNImg1 from '../images/galleries/1/info_cn.png';
import galleryInfoENImg1 from '../images/galleries/1/info_en.png';
import galleryInfoCNImg2 from '../images/galleries/2/info_cn.png';

import galleryPrefaceCNImg0 from '../images/galleries/0/preface_cn.png';
import galleryPrefaceENImg0 from '../images/galleries/0/preface_en.png';
import galleryPrefaceCNImg1 from '../images/galleries/1/preface_cn.png';
import galleryPrefaceENImg1 from '../images/galleries/1/preface_en.png';
import galleryPrefaceCNImg2 from '../images/galleries/2/preface_cn.png';

import galleryPrefaceBGImg0 from '../images/galleries/0/preface_bg.png';
import galleryPrefaceBGImg1 from '../images/galleries/1/preface_bg.png';
import galleryPrefaceBGImg2 from '../images/galleries/2/preface_bg.png';

import galleryBtnNextImg0 from '../images/galleries/0/btn_next.png';
import galleryBtnNextImg1 from '../images/galleries/1/btn_next.png';
import galleryBtnNextImg2 from '../images/galleries/2/btn_next.png';

import galleryBtnBackImg0 from '../images/galleries/0/btn_back.png';
import galleryBtnBackImg1 from '../images/galleries/1/btn_back.png';
import galleryBtnBackImg2 from '../images/galleries/2/btn_back.png';

import galleryBtnEnterCNImg0 from '../images/galleries/0/btn_enter_cn.png';
import galleryBtnEnterENImg0 from '../images/galleries/0/btn_enter_en.png';
import galleryBtnEnterCNImg1 from '../images/galleries/1/btn_enter_cn.png';
import galleryBtnEnterENImg1 from '../images/galleries/1/btn_enter_en.png';

import galleryBtnLanCNImg0 from '../images/galleries/0/btn_lan_cn.png';
import galleryBtnLanENImg0 from '../images/galleries/0/btn_lan_en.png';
import galleryBtnLanCNImg1 from '../images/galleries/1/btn_lan_cn.png';
import galleryBtnLanENImg1 from '../images/galleries/1/btn_lan_en.png';

import pointBarImg from '../images/point_bar.png';

import galleryImg0 from '../images/galleries/0/entry.png';
import galleryImg1 from '../images/galleries/1/entry.png';
import galleryImg2 from '../images/galleries/2/entry.png';
// import galleryImg3 from '../images/galleries/3.png';

import gallerySidebarBgImg0 from '../images/galleries/0/sidebar_bg.png';
import gallerySidebarBgImg1 from '../images/galleries/1/sidebar_bg.png';
import gallerySidebarBgImg2 from '../images/galleries/2/sidebar_bg.png';

import gallerySidebarTextImg0 from '../images/galleries/0/sidebar_text.png';
import gallerySidebarTextImg1 from '../images/galleries/1/sidebar_text.png';
import gallerySidebarTextImg2 from '../images/galleries/2/sidebar_text.png';

import galleryBtnContentsImg0 from '../images/galleries/0/btn_contents.png';
import galleryBtnContentsImg1 from '../images/galleries/1/btn_contents.png';
import galleryBtnContentsImg2 from '../images/galleries/2/btn_contents.png';

import galleryMenuCNImg0 from '../images/galleries/0/menu_cn.png';
import galleryMenuENImg0 from '../images/galleries/0/menu_en.png';
import galleryMenuCNImg1 from '../images/galleries/1/menu_cn.png';
import galleryMenuENImg1 from '../images/galleries/1/menu_en.png';
import galleryMenuCNImg2 from '../images/galleries/2/menu_cn.png';

import bgImg from '../images/bg_wh.png';

import tipMaskImg from '../images/tip_mask.png';
import iconSlideImg from '../images/icon_slide.png';
import iconSlideWhiteImg from '../images/icon_slide_white.png';
import endingENImg from '../images/ending_en.png';
import endingCNImg from '../images/ending_cn.png';
// import textStartingImg from '../images/text_starting.png';
// import textEndingImg from '../images/text_ending.png';

import galleryBtnCloseImg0 from '../images/galleries/0/btn_close.png';
import galleryBtnCloseImg1 from '../images/galleries/1/btn_close.png';
import galleryBtnCloseImg2 from '../images/galleries/2/btn_close.png';

import galleryShadowBGImg0 from '../images/galleries/0/shadow_bg.png';
import galleryShadowBGImg1 from '../images/galleries/1/shadow_bg.png';
import galleryShadowBGImg2 from '../images/galleries/2/shadow_bg.png';

import btnPortfolioImg from '../images/btn_portfolio.png';
import btnClosePortfolioImg from '../images/btn_close_portfolio.png';
import btnLeftPortfolioImg from '../images/btn_left_portfolio.png';
import btnRightPortfolioImg from '../images/btn_right_portfolio.png';
// import btnShareImg from '../images/btn_share.png';
// import btnRectLeftImg from '../images/bg_rect_left.png';
// import btnRectRightImg from '../images/bg_rect_right.png';
import bgDotWorkInfoImg from '../images/bg_dot_work_info.png';

import numberRegionImg from '../images/number_region.png';
import numberRegionXml from '../images/number_region.xml';

import numberPortfolioImg from '../images/number_portfolio.png';
import numberPortfolioXml from '../images/number_portfolio.xml';

import timeFontImg from '../images/time_font.png';
import timeFontXml from '../images/time_font.xml';

import btnZoomInImg from '../images/btn_zoom_in.png';
import btnZoomOutImg from '../images/btn_zoom_out.png';
import iconZoomProgressImg from '../images/icon_zoom_progress.png';

import hotspotImg from '../images/hotspot.png';
import hotspotSlideImg from '../images/hotspot_slide.png';
import btnPlayImg from '../images/btn_play.png';

import iconVideoProgressImg from '../images/icon_video_progress.png';
import controllerPlayImg from '../images/controller_play.png';
import controllerPauseImg from '../images/controller_pause.png';
import controllerLoadingImg from '../images/controller_loading.png';

var ScenePreload = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreload ()
  {
    Phaser.Scene.call(this, { key: 'scenePreload', active: false });

    this.photoSourcesLoaded = false;
    this.photoSourcesAdded = false;
  },

  init: function(params){
    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.params = params;
  },

  preload: function ()
  {

    if(this.params.personal) {
      console.log(this.params.personal);
      this.load.image(this.params.personal.texture, this.params.personal.path);  
    }

    // for(let i = 0; i < 200; i ++) {
    //   this.load.image('animation_' + i, require('../images/v2/' + i + '.png').default);
    // }
    


    this.load.image('text_loading', textLoadingImg);
    this.load.image('bg_home', bgHomeImg);
    const ballImgs = [
      ballImg0, ballImg1, ballImg2, ballImg3, ballImg4
    ];
    ballImgs.forEach((img, i) => {
      this.load.image('ball_' + i, img);
    });
    this.load.image('light', lightImg);
    this.load.image('title', titleImg);
    // this.load.image('copyright', copyrightImg);
    this.load.image('btn_enter', btnEnterImg);
    this.load.image('home_text', homeTextImg);

    const galleryBtnBackPrafaceImgs = [
      galleryBtnBackPrafaceImg0,
      galleryBtnBackPrafaceImg1,
      galleryBtnBackPrafaceImg2
    ];
    galleryBtnBackPrafaceImgs.forEach((img, i) => {
      this.load.image('gallery_btn_back_preface_' + i, img);
    });

    const galleryBtnLanPrefaceCNImgs = [
      galleryBtnLanPrefaceCNImg0,
      galleryBtnLanPrefaceCNImg1
    ];
    galleryBtnLanPrefaceCNImgs.forEach((img, i) => {
      this.load.image('gallery_btn_lan_preface_cn_' + i, img);
    });

    const galleryBtnLanPrefaceENImgs = [
      galleryBtnLanPrefaceENImg0,
      galleryBtnLanPrefaceENImg1
    ];
    galleryBtnLanPrefaceENImgs.forEach((img, i) => {
      this.load.image('gallery_btn_lan_preface_en_' + i, img);
    });

    this.load.image('bg_menu', bgMenuImg);
    this.load.image('btn_back_white', btnBackWhiteImg);
    this.load.image('main_exhibition', mainExhibitionImg);

    const galleryInfoCNImgs = [
      galleryInfoCNImg0,
      galleryInfoCNImg1,
      galleryInfoCNImg2
    ];
    galleryInfoCNImgs.forEach((img, i) => {
      this.load.image('gallery_info_cn_' + i, img);
    });

    const galleryInfoENImgs = [
      galleryInfoENImg0,
      galleryInfoENImg1
    ];
    galleryInfoENImgs.forEach((img, i) => {
      this.load.image('gallery_info_en_' + i, img);
    });

    const galleryPrefaceCNImgs = [
      galleryPrefaceCNImg0,
      galleryPrefaceCNImg1,
      galleryPrefaceCNImg2
    ];
    galleryPrefaceCNImgs.forEach((img, i) => {
      this.load.image('gallery_preface_cn_' + i, img);
    });

    const galleryPrefaceENImgs = [
      galleryPrefaceENImg0,
      galleryPrefaceENImg1,
    ];
    galleryPrefaceENImgs.forEach((img, i) => {
      this.load.image('gallery_preface_en_' + i, img);
    });

    const galleryPrefaceBGImgs = [
      galleryPrefaceBGImg0,
      galleryPrefaceBGImg1,
      galleryPrefaceBGImg2,
    ];
    galleryPrefaceBGImgs.forEach((img, i) => {
      this.load.image('gallery_preface_bg_' + i, img);
    });

    const galleryBtnNextImgs = [
      galleryBtnNextImg0,
      galleryBtnNextImg1,
      galleryBtnNextImg2,
    ];
    galleryBtnNextImgs.forEach((img, i) => {
      this.load.image('gallery_btn_next_' + i, img);
    });

    const galleryBtnEnterCNImgs = [
      galleryBtnEnterCNImg0,
      galleryBtnEnterCNImg1,
    ];
    galleryBtnEnterCNImgs.forEach((img, i) => {
      this.load.image('gallery_btn_enter_cn_' + i, img);
    });

    const galleryBtnEnterENImgs = [
      galleryBtnEnterENImg0,
      galleryBtnEnterENImg1,
    ];
    galleryBtnEnterENImgs.forEach((img, i) => {
      this.load.image('gallery_btn_enter_en_' + i, img);
    });

    const galleryBtnBackImgs = [
      galleryBtnBackImg0,
      galleryBtnBackImg1,
      galleryBtnBackImg2,
    ];
    galleryBtnBackImgs.forEach((img, i) => {
      this.load.image('gallery_btn_back_' + i, img);
    });

    const galleryBtnLanCNImgs = [
      galleryBtnLanCNImg0,
      galleryBtnLanCNImg1,
    ];
    galleryBtnLanCNImgs.forEach((img, i) => {
      this.load.image('gallery_btn_lan_cn_' + i, img);
    });

    const galleryBtnLanENImgs = [
      galleryBtnLanENImg0,
      galleryBtnLanENImg1,
    ];
    galleryBtnLanENImgs.forEach((img, i) => {
      this.load.image('gallery_btn_lan_en_' + i, img);
    });
    
    this.load.image('point_bar', pointBarImg);

    const galleryImgs = [
      galleryImg0, galleryImg1, galleryImg2
    ];
    galleryImgs.forEach((img, i) => {
      this.load.image('gallery_' + i, img)
    })

    const gallerySidebarBgImgs = [
      gallerySidebarBgImg0,
      gallerySidebarBgImg1,
      gallerySidebarBgImg2,
    ];
    gallerySidebarBgImgs.forEach((img, i) => {
      this.load.image('gallery_sidebar_bg_' + i, img);
    });

    const gallerySidebarTextImgs = [
      gallerySidebarTextImg0,
      gallerySidebarTextImg1,
      gallerySidebarTextImg2,
    ];
    gallerySidebarTextImgs.forEach((img, i) => {
      this.load.image('gallery_sidebar_text_' + i, img);
    });

    const galleryBtnContentsImgs = [
      galleryBtnContentsImg0,
      galleryBtnContentsImg1,
      galleryBtnContentsImg2
    ];
    galleryBtnContentsImgs.forEach((img, i) => {
      this.load.image('gallery_btn_contents_' + i, img);
    });

    const galleryBtnCloseImgs = [
      galleryBtnCloseImg0,
      galleryBtnCloseImg1,
      galleryBtnCloseImg2
    ];
    galleryBtnCloseImgs.forEach((img, i) => {
      this.load.image('gallery_btn_close_' + i, img);
    });

    const galleryShadowBGImgs = [
      galleryShadowBGImg0,
      galleryShadowBGImg1,
      galleryShadowBGImg2
    ];
    galleryShadowBGImgs.forEach((img, i) => {
      this.load.image('gallery_shadow_bg_' + i, img);
    });

    const galleryMenuCNImgs = [
      galleryMenuCNImg0,
      galleryMenuCNImg1,
      galleryMenuCNImg2
    ];
    galleryMenuCNImgs.forEach((img, i) => {
      this.load.image('gallery_menu_cn_' + i, img);
    });

    const galleryMenuENImgs = [
      galleryMenuENImg0,
      galleryMenuENImg1,
    ];
    galleryMenuENImgs.forEach((img, i) => {
      this.load.image('gallery_menu_en_' + i, img);
    });

    this.load.image('bg', bgImg);
    this.load.image('tip_mask', tipMaskImg);
    this.load.image('icon_slide', iconSlideImg);
    this.load.image('icon_slide_white', iconSlideWhiteImg);
    this.load.image('ending_en', endingENImg);
    this.load.image('ending_cn', endingCNImg);
    // this.load.image('text_starting', textStartingImg);
    // this.load.image('text_ending', textEndingImg);

    this.load.image('btn_portfolio', btnPortfolioImg);
    this.load.image('btn_close_portfolio', btnClosePortfolioImg);
    this.load.image('btn_left_portfolio', btnLeftPortfolioImg);
    this.load.image('btn_right_portfolio', btnRightPortfolioImg);
    // this.load.image('btn_share', btnShareImg);
    // this.load.image('bg_rect_left', btnRectLeftImg);
    // this.load.image('bg_rect_right', btnRectRightImg);
    this.load.image('bg_dot_work_info', bgDotWorkInfoImg);
    this.load.bitmapFont('numberRegionFont', numberRegionImg, numberRegionXml);
    this.load.bitmapFont('numberPortfolioFont', numberPortfolioImg, numberPortfolioXml);

    this.load.bitmapFont('timeFont', timeFontImg, timeFontXml);
    
    this.load.image('btn_zoom_in', btnZoomInImg);
    this.load.image('btn_zoom_out', btnZoomOutImg);
    this.load.image('icon_zoom_progress', iconZoomProgressImg);

    // load hotspot
    this.load.image('hotspot', hotspotImg);
    this.load.image('hotspot_slide', hotspotSlideImg);
    this.load.image('btn_play', btnPlayImg);

    this.load.image('icon_video_progress', iconVideoProgressImg);
    this.load.image('controller_play', controllerPlayImg);
    this.load.image('controller_pause', controllerPauseImg);
    this.load.image('controller_loading', controllerLoadingImg);

    this.scene.launch('sceneLoading');
    this.isReady = false;
    
    this.load.on('progress', function (progress, e){
      this.scene.get('sceneLoading').setLoadingProgress(parseInt(progress*100));
      if(progress >= 1) {
        this.isReady = true;
      }
    }, this);
    
  },

  create: function ()
  {
  },

  update: function(){
    if(this.isReady){
      this.isReady = false;

      // if(this.textures.getTextureKeys().indexOf('start') >= 0 ) {
      //   this.scene.launch('sceneTitle');
      //   this.scene.launch('sceneEnter', this.params);
      //   this.scene.launch('sceneAnimation');
      // }

      this.scene.get('sceneLoading').closeLoadingMask();
      this.scene.get('sceneLoading').moveRight();
      
      this.scene.start('sceneModel');
      this.scene.launch('sceneEnter', this.params);
    } 
  },

});
export default ScenePreload;