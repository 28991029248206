'use strict';

import scaler from "./scaler";
import GC from "./const";

import emptyImg from '../images/empty.png';
import textLoadingBlueImg from '../images/text_loading_blue.png';
// import iconLoadingImg from '../images/icon_loading.png';

import loadingFontImg from '../images/loading_font.png';
import loadingFontBlueImg from '../images/loading_font_blue.png';
import loadingFontXml from '../images/loading_font.xml';
import btnRefreshImg from '../images/btn_refresh.png';
import bgLoadingBlueImg from '../images/bg_loading_blue.png';
import bgLoadingImg from '../images/bg_loading.png';



var SceneBoot = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneBoot ()
  {
    Phaser.Scene.call(this, { key: 'sceneBoot', active: true });

    this.currentScene = 'sceneBoot';
  },

  init: function(params){
    this.scaler = scaler;
    this.windowResized = false;
    this.bgSprite;
    this.textMobile;
 
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;
    this.recalculateSize();

    this.sceneToResize = [
      this.scene.get('sceneLoading'),
      this.scene.get('sceneEnter'),
      this.scene.get('scenePreface'),
      this.scene.get('sceneAuthors'),
      this.scene.get('sceneAuthorList'),
      this.scene.get('sceneGallery'),
      this.scene.get('sceneGalleryInfo'),
      this.scene.get('sceneModel'),
      this.scene.get('sceneMenu1'),
      this.scene.get('sceneMenu2'),
      this.scene.get('sceneMenu3'),
      this.scene.get('sceneDetail'),
      this.scene.get('sceneMain'),
      this.scene.get('sceneSideBar'),
    ];
    

    this.scaleMode = 'height';
    this.scaler.scaleMode = 'height';

    this.screenIsRightDirection = true;

    //竖屏
    this._designRefWidth = 1080;
    this._designRefHeight = 1920;

    this.getRegionDate = false;
  },

  preload: function ()
  {
    this.load.image('empty', emptyImg);
    this.load.image('text_loading_blue', textLoadingBlueImg);
    this.load.bitmapFont('loadingFont', loadingFontImg, loadingFontXml);
    this.load.bitmapFont('loadingFontBlue', loadingFontBlueImg, loadingFontXml);
    this.load.image('btn_refresh', btnRefreshImg);
    this.load.image('bg_loading_blue', bgLoadingBlueImg);
    this.load.image('bg_loading', bgLoadingImg);
  },

  create: function ()
  {
    var sprite, designX, designY;

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'bg_preface'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteByMax(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   scaleByMax: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.initialScale = sprite.scale;
    // this.bgSprite = sprite;

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'icon_2020_color'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteFill(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   fill: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.initialScale = sprite.scale;
    // this.icon2020ColorSprite = sprite;

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'mask'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSpriteFill(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   fill: true,
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.initialScale = sprite.scale;
    // this.maskSprite = sprite;

    designX = 0;
    designY = 0;
    var sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'bg_loading'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };
    // sprite.setVisible(false);

    this.windowResized = true;

    this.scale.on('resize',function resize(gameSize, baseSize, displaySize) {
      // if(this.scene.isActive('sceneEnter')){
      //   this.recalculateSize2();
      // } else{
      //   this.recalculateSize();
      // }
      if(this.scaleMode == 'full'){
        this.recalculateSize2();
      } else{
        this.recalculateSize();
      }
      
      this.windowResized = true;
    }, this);    

    //test
    // this.scene.launch('scenePreload');

    // this.bgAnimation();
  },

  update: function(){
    if (this.windowResized) {
      this.scale.resize(this.width, this.height);
      this.resized();
      this.windowResized = false;
    }

    // this.colourBarSprite.tilePositionX += this.scaler.vScale()*0.7;

    if(this.getRegionDate == false && JSON.stringify(window.custom.systemList) !== '{}'){
      this.getRegionDate = true;
      var rid = utils.getUrlParam('rid');
      var locator = utils.getUrlParam('locator');

      var sid = utils.getUrlParam('sid');
      this.toAnalysisParams(rid, locator, sid);
    }
  },

  recalculateSize2: function() {
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;
  
    // // resize the canvas
    let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.width = this.width;
      canvas.height = this.height;
    }
  
    let designRefWidth = GC.resolution * this.width/this.height;
    // let designRefHeight = GC.resolution;
  
    if(this.width >= this.height){
      this.scaler.config(this.width, this.height, 1920, 1080);
    } else{
      this.scaler.config(this.width, this.height, 1080, 1920);
    }
    
  },

  recalculateSize: function() {
    this.width = document.getElementById('phaser-wrapper').clientWidth * window.devicePixelRatio;
    this.height = document.getElementById('phaser-wrapper').clientHeight * window.devicePixelRatio;

    // // resize the canvas
    let canvas = document.getElementById('phaser-wrapper').getElementsByTagName('canvas')[0];
    if (canvas) {
      canvas.width = this.width;
      canvas.height = this.height;
    }

    let designRefWidth = GC.resolution * this.width/this.height;
    let designRefHeight = GC.resolution;

    this.scaler.config(this.width, this.height, designRefWidth, designRefHeight);
  },

  resized: function () {
    if(this.scaler.width >= this.scaler.height) {
      if(window.needOffset) {
        window.sidebarOffset = 70;
      }

      this.cameras.main.rotation = 0;
      this.cameras.main.centerOnX(this.scaler.width/2);
      this.cameras.main.centerOnY(this.scaler.height/2);

      this.sceneToResize.forEach(scene => {
        if(scene && scene.cameras.main){
          scene.cameras.main.rotation = 0;
          scene.cameras.main.centerOnX(this.scaler.width/2);
          scene.cameras.main.centerOnY(this.scaler.height/2);
        }
      });
    } else{
      window.sidebarOffset = 0;

      this.cameras.main.rotation = 3.14/2;
      this.cameras.main.centerOnX(this.scaler.height/2);
      this.cameras.main.centerOnY(this.scaler.width/2);

      this.sceneToResize.forEach(scene => {
        if(scene && scene.cameras.main){
          scene.cameras.main.rotation = 3.14/2;
          scene.cameras.main.centerOnX(this.scaler.height/2);
          scene.cameras.main.centerOnY(this.scaler.width/2);
        }
      }); 
    }

    utils.resized(this);

    this.sceneToResize.forEach(scene => {
      if(scene && scene.resized && scene.scene.isActive()){
        scene.resized();
      }
    });
  },

  getUrlParam: function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },

  showRegionBg: function(){
    // var lightIndexs = [0, 4, 7, 10, 11];
    // var theme = 'dark';
    // if(lightIndexs.indexOf(this.sceneGuide.systemIndex) >= 0){
    //   theme = 'light';
    // }
    // this.bgSprite.setTexture('bg_' + theme);
    // this.bgSprite.visible = true;
    // this.colourBarSprite.visible = false;
  },

  scaleSprite: function(sprite) {
    sprite.setScale(this.hScale(), this.vScale());
  },

  scaleSpriteByHeight: function(sprite) {
    sprite.setScale(this.vScale(), this.vScale());
  },

  scaleX: function (designX, align) {
    let alignment = (align || 'middle').toLowerCase();

    if (alignment == 'top') {
        return designX * this.hScale();
    } else if (alignment == 'bottom') {
        return this.offsetPos().x * 2 + designX * this.hScale();
    } else {
        return this.offsetPos().x + designX * this.hScale();
    }
  },

  scaleY: function(designY, align, offset) {
    let alignment = (align || 'middle').toLowerCase();
    offset = offset || 0;
    if(alignment == 'top') {
        return this.offsetPos().y * offset + designY * this.vScale();
    } else if (alignment == 'bottom') {
        return this.offsetPos().y * 2 + designY * this.vScale();
    } else {
        return this.offsetPos().y + designY * this.vScale();
    }
  },

  hScale: function() {
    // return(this.game.canvas.width / this._designRefWidth);
    if(this.offsetPos().x != 0)
        return (this.height / this._designRefHeight);
    else
        return (this.width / this._designRefWidth);
  },

  vScale: function() {
    // return(this.game.canvas.height / this._designRefHeight);
    if(this.offsetPos().y != 0)
        return (this.width / this._designRefWidth);
    else
        return (this.height / this._designRefHeight);
  },

  offsetPos: function() {
    var offsetX = 0;
    var offsetY = 0;
    var ws = this.width / this._designRefWidth;
    var hs = this.height / this._designRefHeight;
    if(ws < hs){
        offsetY = (this.height - this._designRefHeight * this.width/this._designRefWidth)/2;
    }else{
        offsetX = (this.width - this._designRefWidth * this.height/this._designRefHeight)/2;
    }
    return {x: offsetX, y: offsetY};
  },

  toAnalysisParams: function(rid, locator, sid) {
    var self = this;
    if(rid && locator){//个人展厅
      var latest = '';
      var label = '';
      if(window.custom.systemList[rid] && window.custom.systemList[rid].latest){
        latest = window.custom.systemList[rid].latest;
        label = window.custom.systemList[rid].label;
      }

      if(latest == ''){
        self.toNextPage();
        return;
      }

      let galleryIndex = 0;
      for(let key in GC.galleryMap) {
        if(GC.galleryMap[key] == rid) {
          galleryIndex = key;
          break;
        }
      }

      //get region data
      $.ajax({
        url: latest,
        type: "GET",
        async: true,
        success: function(data) {
          console.log('GET IMAGES SUCCESS!');
          let regionsRaw = data.regions;
          window.publicPath = data.publicPath;
          window.theme = data.theme;
  
          var texture;
          var index;
          for(var i = 0; i < regionsRaw.length; i ++){
            if(regionsRaw[i].meta.locator == locator){
              index = i;
              texture = regionsRaw[i].meta.name.texture;
            }
          }
  
          if(texture && index != undefined){
            var personal = {
              texture: texture,
              path: data.publicPath + regionsRaw[index].preloadMaterials.images[texture]
            };
            self.toNextPage({
              personal: personal,
              latest: latest,
              galleryIndex: galleryIndex,
              locator: locator,
            });
          } else{
            //
            self.toNextPage();
          }
          
        }, error : function(response) {
          console.log("GET IMAGES FAILED!");
          console.log(JSON.stringify(response));
  
          self.toNextPage();
        }
      });
    } else if(sid){//系长廊
      var latest = '';
      var label = '';
      
      let galleryIndex = 0;
      for(let key in GC.galleryMap) {
        if(GC.galleryMap[key] == sid) {
          galleryIndex = key;
          break;
        }
      }
      self.toNextPage({system: true, galleryIndex: galleryIndex});
    } else{//正常模式
      this.toNextPage();
    }
    
  },

  toNextPage: function(params){
    if(params){
      this.scene.launch('scenePreload', params);
    } else{
      this.scene.launch('scenePreload');
    }
  },

  bgAnimation: function(){
    this.tweens.add({
      targets: this.bgSprite,
      scale: this.bgSprite.initialScale * 1.2,
      duration: 3000,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
        
      },
    }); 

    this.tweens.add({
      targets: this.icon2020ColorSprite,
      scale: this.icon2020ColorSprite.initialScale * 1.1,
      duration: 3000,
      delay: 1000,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
        
      },
    }); 
  },

  hideLogo: function(){
    this.logoSprite.setVisible(false);
  },

  showLogo: function(){
    this.logoSprite.setVisible(true);
  },
});
export default SceneBoot;