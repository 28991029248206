'use strict';

import Scroller from "./scroller";

var SceneAuthorList = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneAuthorList ()
  {
    Phaser.Scene.call(this, { key: 'sceneAuthorList', active: false });
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'bg_preface'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    designX = 306.5;
    designY = 204.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleBgY(designY),
      'title_authors_1'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgY: true,
    };

    this.scroller = new Scroller(this, this.scaler, 'Vertical');

    designX = 253;
    designY = 554;
    sprite = this.add.rectangle(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      1415,
      this.scaler.designRefHeight()
      , 0x000000, 0);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFillHeight(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      scaleFillHeight: true,
    };
    const bgSprite = sprite;
    
    this.dragSprites = [];
    this.avatarSprites = [];
    this.nameSprites = [];

    this.createList('title_teacher', 170, 0, 35);

    let lastAvatarSprite =  this.avatarSprites[this.avatarSprites.length - 1];

    let startY = lastAvatarSprite.keepData.designY + lastAvatarSprite.height/2 + 50;
    this.createList('title_student', startY, 35, 5);

    lastAvatarSprite =  this.avatarSprites[this.avatarSprites.length - 1];
    startY = lastAvatarSprite.keepData.designY + lastAvatarSprite.height/2 + 50;
    this.createList('title_company', startY, 40, 6);

    [this.avatarSprites, this.nameSprites].forEach((sprites, index) => {
      sprites.forEach((sprite, index) => {
        sprite.on("pointerdown", pointer => {
          if(this.scroller.dragMaxOffset > 5) {
            return
          }
          sprite.isPressed = true;
          sprite.alpha = 0.5;
          this.nameSprites[index].alpha = 0.5;
          this.avatarSprites[index].alpha = 0.5;
        });
  
        sprite.on("pointerup", pointer => {
          if(this.scroller.dragMaxOffset > 5) {
            return
          }
          if(sprite.isPressed) {
            this.toRegion(sprite.keepData.index);
          }
          sprite.isPressed = false;
          sprite.alpha = 1;
          this.nameSprites[index].alpha = 1;
          this.avatarSprites[index].alpha = 1;
        });
      });
    });

    this.scroller.setDragBg(bgSprite);

    if(this.dragSprites.length > 0) {
      this.scroller.setDragSprites(this.dragSprites);
      this.scroller.setScrollerBounds({
        top: 145,
        bottom: -174,
      });
    }
    
    designX = 171;
    designY = 945.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_back'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
      });
    }
  },

  update: function(){
    if(this.animationing) {
      return;
    }
    this.scroller.checkEnding();
    this.scroller.checkCrop();
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  createList: function(titleSource, startY, startIndex, number) {
    let designX, designY, sprite;
    designX = -221;
    designY = startY;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      titleSource,
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      xlocation: 'center',
    };
    this.dragSprites.push(sprite);

    for(let i = 0; i < number; i ++) {
      designX = -301;
      designY = startY + 136 + i * 188;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX, 'center'),
        this.scaler.scaleY(designY),
        'avatars', startIndex + i
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSprite(sprite);
      const scale = 125/145;
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY,
        xlocation: 'center',
        scale: scale,
        enableClick: true,
        index: startIndex + i,
      };
      sprite.scale *= scale;
      this.dragSprites.push(sprite);
      this.avatarSprites.push(sprite);

      designX = -221;
      designY = startY + 150 +  i * 188;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX, 'center'),
        this.scaler.scaleY(designY),
        'titles', startIndex + i
      );
      sprite.setOrigin(0, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        designX: designX,
        designY: designY,
        xlocation: 'center',
        enableClick: true,
        index: startIndex + i,
      };
      this.dragSprites.push(sprite);
      this.nameSprites.push(sprite);
    }
  },

  toRegion: function(i) {
    const rid = Locators[i].gallery;
    const locator = Locators[i].locator;
    const galleryId = GC.galleryMap.indexOf(rid);
    window.custom.locator = locator;

    this.hide();
    utils.toGallery(this.scene, galleryId, false, 'sceneAuthorList');
  },

  hide: function() {
    const self = this;
    utils.disableAllButtons(this);
    
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.scene.setVisible(false);
      self.scene.sendToBack();
    });
  },

  show: function() {
    utils.enableAllButtons(this);
    this.animationing = false;
    this.cameras.main.alpha = 1;
    this.scene.setVisible(true);
    this.scene.bringToTop();
  },

  back: function() {
    this.scene.start('sceneGallery', {
      animation: false,
    });
  },
});
export default SceneAuthorList;