'use strict';
// 首页

var SceneEnter = new Phaser.Class({

  Extends: Phaser.Scene,
  // Extends: Scene3D,

  initialize:

  function SceneEnter ()
  {
    Phaser.Scene.call(this, { key: 'sceneEnter', active: false });
  },

  init: function(params){
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'sceneEnter';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;

    this.clickedEnterBtn = false;

  },

  preload: function ()
  {
  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    this.homeGroup = this.createHomeGroup();

    this.enterActionGroup = this.createBtnEnterGroup();
    
    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    this.sceneBoot.windowResized = true;

    if(this.params && this.params.personal){
      //个人展位
      self.showBtnAnimation();
    } else if(this.params && this.params.system){
      //系长廊
      self.showBtnAnimation();
    }else{
      //正常模式
      self.getCookie();
    }

    this.ballSprites.forEach(sprite => {
      this.ballAnimation(sprite);
    });
    this.lightAnimation();
  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);

    this.ballSprites.forEach(sprite => {
      this.ballAnimation(sprite);
    });
    // this.lightAnimation();
  },

  createHomeGroup: function() {
    var self = this;
    var designX, designY, sprite;

    var group = this.add.group();

    designX = 0;
    designY = 0;
    var sprite = this.add.image(
      this.scaler.scaleX(designX),
      this.scaler.scaleY(designY),
      'bg_home'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    // designX = 0;
    // designY = 0;
    // var sprite = this.add.image(
    //   this.scaler.scaleBgX(designX, 'center'),
    //   this.scaler.scaleY(designY, 'center'),
    //   'animation_0'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // this.scaler.scaleSprite(sprite);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   designX: designX,
    //   designY: designY,
    //   // scale: 0.5
    // };
    // this.animationSprite = sprite;

    designX = -144;
    designY = 685;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'light'
    );
    sprite.setOrigin(0, 1);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      // ylocation: 'top',
      // scaleBgY: true,
      designX: designX,
      designY: designY,
    };
    group.add(sprite);
    this.lightSprite = sprite;

    const param = [
      {x: 102, y: -53, target: {x: 53, y: -100}, xlocation: 'left', scaleBgY: true, source: 'ball_0'},
      {x: -272.5, y: 586.5, target: {x: -298.5, y: 580.5}, source: 'ball_1'},
      {x: -153, y: 747, target: {x: -179, y: 741}, source: 'ball_2'},
      {x: 223, y: 747, target: {x: 197, y: 741}, source: 'ball_3'},
      {x: 580, y: 878, target: {x: 508, y: 888}, source: 'ball_4'},
    ];
    this.ballSprites = [];
    param.forEach(p => {
      designX = p.x;
      designY = p.y;
      sprite = this.add.image(
        this.scaler.scaleBgX(designX, 'center'),
        this.scaler.scaleY(designY),
        p.source
      );
      sprite.setOrigin(0.5, 0.5);
      this.scaler.scaleSprite(sprite);
      sprite.keepData = {
        resized: true,
        xlocation: p.xlocation ? p.xlocation : 'center',
        ylocation: 'bottom',
        designX: designX,
        designY: designY,
        target: p.target,
      };
      if(p.scaleBgY) {
        sprite.keepData.scaleBgY = true;
      }
      group.add(sprite);
      this.ballSprites.push(sprite);
    });


    designX = -342.5;
    designY = 519.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'home_text'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
    };
    group.add(sprite);

    designX = -30;
    designY = -73;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleBgY(designY, 'bottom'),
      'title'
    );
    sprite.setOrigin(1, 1);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      ylocation: 'bottom',
      scaleBgY: true,
      designX: designX,
      designY: designY,
    };
    group.add(sprite);

    return group;
  },

  createBtnEnterGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = 135;
    designY = 584.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'btn_enter');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    this.btnEnterSprite = sprite;
    var btnEnterSprite = sprite;
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);
      if(btnEnterSprite.isPressed) {
        this.clickEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });
    return group;
  },

  createBtnEnterPersonalGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -160;
    designY = 750;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_enter_personal');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    var btnEnterSprite = sprite;
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);

      if(btnEnterSprite.isPressed) {
        this.clickPersonalEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });

    designX = -266;
    designY = 767;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      this.params.personal.texture);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);

      if(btnEnterSprite.isPressed) {
        this.clickPersonalEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });

    return group;
  },

  createBtnEnterSystemGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -160;
    designY = 750;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_enter_system_' + this.params.systemIndex);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    var btnEnterSprite = sprite;
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);

      if(btnEnterSprite.isPressed) {
        this.clickSystemEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });
    return group;
  },

  clickEnterButton: function() {
    const self = this;
    if(this.params && this.params.personal) {
      // 个人展位
      this.clickPersonalEnterButton();
    } else if(this.params && this.params.system) {
      // 系长廊
      this.clickSystemEnterButton();
    } else {
      // 正常模式
      utils.disableAllButtons(this);
      
      this.animationing = true;
      utils.sceneFadeOutAnimation(this, function() {
        self.animationing = false;
        self.scene.stop();
      });

      this.scene.launch('sceneGallery', {
        animation: true,
      });
    }
  },

  clickPersonalEnterButton: function() {
    window.custom.locator = this.params.locator;
    utils.disableAllButtons(this);
    this.slideOutAnimation();
    this.scene.get('sceneAnimation').outAnimation();

    utils.toGallery(this.scene, this.params.galleryIndex);
  },

  clickSystemEnterButton: function() {
    utils.disableAllButtons(this);
    this.slideOutAnimation();
    this.scene.get('sceneAnimation').outAnimation();

    utils.toGallery(this.scene, this.params.galleryIndex);
  },

  getCookie: function(){
    var self = this;
    var selected = null;
    try {
      selected = JSON.parse(decodeURIComponent(utils.getCookie('selected')));
    }
    catch(err) {
      console.log(err);
    }
  
    window.custom.locator = utils.getCookie('locator');

    if(
      selected == null ||
      !(selected.galleryId >= 0 && selected.galleryId <= 3)
    ){
      self.showBtnAnimation();
      return;
    }
  
    window.custom.selected = selected;
  
    window.custom.noTip = true;

    this.scene.stop();

    this.scene.start('sceneGalleryInfo', {
      galleryId: selected.galleryId,
      animation: false,
      hide: true,
    });
    utils.toGallery(this.scene, selected.galleryId, selected.lan, selected.lan, true);
  },
  
  showBtnAnimation: function(){
    var self = this;
    this.enterActionGroup.setVisible(true);
    this.enterActionGroup.getChildren().forEach((s, index) => {
      this.tweens.add({
        targets: s,
        alpha: {
          start: 0,
          to: s.keepData.alpha
        },
        // y: {
        //   from: this.scaler.scaleY(s.keepData.designY - 30),
        //   to: this.scaler.scaleY(s.keepData.designY)
        // },
        duration: 1000,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        yoyo: false,
        onComplete: function () {
        },
      }); 
    });
  },

  ballAnimation: function(sprite, target) {
    if(sprite.tween) {
      sprite.tween.remove();
      sprite.tween = null
    }
    let startY, targetY;
    if(sprite.keepData.scaleBgY) {
      startY = this.scaler.scaleBgY(sprite.keepData.designY, 'bottom');
      targetY = this.scaler.scaleBgY(sprite.keepData.target.y, 'bottom');
    } else {
      startY = this.scaler.scaleY(sprite.keepData.designY);
      targetY = this.scaler.scaleY(sprite.keepData.target.y);
    }
    sprite.tween = this.tweens.add({
      targets: sprite,
      x: {
        start: this.scaler.scaleBgX(sprite.keepData.designX, sprite.keepData.xlocation),
        to: this.scaler.scaleBgX(sprite.keepData.target.x, sprite.keepData.xlocation)
      },
      y: {
        start: startY,
        to: targetY
      },
      duration: 2040,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
      },
    }); 
  },

  lightAnimation: function() {
    const sprite = this.lightSprite;
    if(sprite.tween) {
      sprite.tween.remove();
      sprite.tween = null
    }
    sprite.tween = this.tweens.add({
      targets: sprite,
      alpha: {
        start: 0,
        to: 1
      },
      duration: 8000,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: false,
      onComplete: function () {
      },
    }); 
  },

  circleAnimation: function() {
    var frame = 0;
    var totalFrame = 200;
    var frames = 25;
    this.circleAnimationTimer = this.time.addEvent({
      delay: 1000/25,
      callback: function(){
        frame ++;
        
        if(frame > totalFrame - 1){
          frame = 0;
        }
        
        // if(frame%frames == 0) {
        //   this.animationSprite.setTexture('animation_' + parseInt(frame/frames));
        // }
        
        this.animationSprite.setTexture('animation_' + frame);
      },
      callbackScope: this,
      loop: true
    });

  },

  // 进入长廊动画
  slideOutAnimation: function(func) {
    const self = this;

    this.animationing = true;
    utils.slideAimation(this, 'out', function() {
      self.scene.stop();
      self.animationing = false;
      if(func) {
        func();
      }
    });
  },
});
export default SceneEnter;