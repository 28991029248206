import '../styles/index.scss';
import '../styles/gallery.scss';

"use strict";

import SceneBoot from './scene-boot';
import ScenePreload from './scene-preload';

import SceneEnter from './scene-enter';
import ScenePreface from './scene-preface';
import SceneAuthors from './scene-authors';
import SceneAuthorList from './scene-author-list';
import SceneGallery from './scene-gallery';
import SceneGalleryInfo from './scene-gallery-info';
import SceneMain from './scene-main';
import SceneLoading from './scene-loading';
import SceneSidebar from './scene-sidebar';

import Utils from './utils';
import GC from "./const";
import Locators from "./locators.json";

window.utils = new Utils();
window.GC = GC;
window.Locators = Locators;

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://41db8880c7e54ab0b4fc3dbaead9240e@sentry.f.onekind.com.cn/6",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

// window.vconsole = new VConsole();
// console.log('#userAgent');
// console.log(window.navigator.userAgent);
if(utils.getCookie('language') != null) {
  GC.language = utils.getCookie('language')
}
// if(utils.getUrlParam('lan') != null) {
//   GC.language = utils.getUrlParam('lan')
// }

window.custom = {
  systemList: {},
  selected: {
    galleryId: 0,
  },
  locator: null,
  noTip: false
};

window.videoCannotPlay = false; // -1 未知状态；1 可以正常播放；2.无法在phaser播放-弹窗播放；3.无法在phaser播放-视频画面不显示

var config = {
  type: Phaser.CANVAS,
  // type: Phaser.AUTO,
  // type: Phaser.WEBGL,
  width: 1920,
  height: 1080,
  scale: {
    mode: Phaser.DOM.RESIZE
    // mode: Phaser.Scale.FIT,
    // autoCenter: Phaser.Scale.CENTER_BOTH
  },
  transparent: true,
  parent: 'phaser-wrapper',
  // scene: [SceneBoot, ScenePreload, SceneLoading, SceneEnter],
  scene: [SceneBoot, ScenePreload, SceneLoading, SceneEnter, ScenePreface, SceneAuthors, SceneAuthorList, SceneGallery, SceneGalleryInfo, SceneMain, SceneSidebar],
  // ...Canvas()
}

var game = new Phaser.Game(config);
window.game = game;
utils.fetchRegions();
window.sidebarOffset = 0;

Zepto(function($) {
  window.needOffset = utils.isIphoneXORIphone11Wechat();
  // window.needOffset = true;

  if(window.needOffset){
    window.sidebarOffset = 70;
  }
  window.videoCannotPlay = utils.getCookie('videoCannotPlay') == 'true' ? true: false;

  utils.wechatShare();

  // window.onbeforeunload = ()=>{
  //   utils.delCookie('selected');
  //   utils.delCookie('locator');
  // };

  // window.addEventListener('pagehide',()=>{
  //   // if(utils.isWechat()){
  //     utils.delCookie('selected');
  //     utils.delCookie('locator');
  //   // }
  // });

  // document.getElementById('btn-close').style.backgroundImage = 'url(' + window.location.origin + '/' + btnSkipImg + ')';
  // document.getElementById('btn-close-region').style.backgroundImage = 'url(' + window.location.origin + '/' + btnCloseImg + ')';
  // document.getElementById('btn-close-portfolio').style.backgroundImage = 'url(' + window.location.origin + '/' + btnCloseImg + ')';
});